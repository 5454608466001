import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';

const TermsOfUse = () => {
  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    fetch(`/legal/terms-of-use/termsOfUse-2024-09-03.html`)
      .then((response) => response.text())
      .then((data) => setHtmlContent(data))
      .catch((error) => console.error("Error loading HTML file:", error));
  }, []);

  return (
    <>
      <Helmet>
        <title>Terms of Use - droplla</title>
        <meta name="description" content="Review the terms and conditions for using droplla's services. Understand your rights and obligations." />
        <link rel="canonical" href="https://www.droplla.com/legal/terms-of-use" />
      </Helmet>
      <Box pt={8} pb={8} pl={10} pr={10} dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </>
  );
};

export default TermsOfUse;