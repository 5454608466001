import React, { useState } from 'react';
import { Box, Heading, Text, Stack, Input, Button, Divider, FormControl, InputGroup, InputLeftElement, Icon, FormErrorMessage, IconButton } from "@chakra-ui/react";
import { useForm } from 'react-hook-form';
import { MdClose, MdOutlineMailOutline, MdOutlineThumbUpAlt } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';
import Logger from '../../../components/utils/logger'

const logger = new Logger('WaitingList');

const WaitingList = () => {

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const onSubmit = async (data) => {
        setIsSubmitting(true);

        const payload = {
            id: uuidv4(),
            kind: 'Public:WaitingList',
            form: data,
            submitted: true,
        };

        try {
            const response = await fetch('/api/users/forms', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (response.status === 204) {
                setIsSubmitted(true)
            } else {
                const errorMessage = 'Internal Error - Please try again in a few minutes';
                setIsSubmitted(false)
                throw new Error(errorMessage);
            }
        } catch (error) {
            logger.error(error)

        } finally {
            setIsSubmitting(false);
        }
    };

    const handleClose = () => {
        reset();
        setIsSubmitted(false);
      };

    return (
        <Box>
            <Box px={{base: 0, lg: 4}} mx="auto"
                textAlign={{
                    md: "center",
                }}
                mb={10}>
                <Heading as="h2"
                    _light={{
                        color: "droplla.500",
                    }}
                    fontWeight="semibold"
                    textTransform="uppercase"
                    letterSpacing="wide"
                    fontSize={{
                        base: "3xl",
                        lg: "4xl",
                    }}
                >
                    Coming Soon
                </Heading>
                <Text mb={4} mt={2}
                    fontSize={{
                        base: "3xl",
                        lg: "4xl",
                    }}
                    fontWeight="bold" lineHeight="1.0" >
                    We are hard at work getting droplla.com out there!
                </Text>
                <Text
                    mb={{base: 2, md: 4}}
                    fontSize={{
                        base: "lg",
                        lg: "xl",
                    }}
                    color="subtleGray.500"
                    _dark={{
                        color: "gray.400",
                    }}
                    lineHeight="tight"
                >
                    Want to be notified when registrations open or get a chance to join our free beta? Just drop your email below.
                </Text>

                {isSubmitted ? (
                  <Box
                  borderWidth="1px"
                  borderRadius="md"
                  p={8}
                  position="relative"
                  textAlign="center"
                  fontSize="lg"
                  color="droplla.500"
                  maxW="800px"
                  mx="auto"  
                >
                  <IconButton
                    icon={<MdClose />}
                    size="md"
                    position="absolute"
                    top="1"
                    right="1"
                    onClick={handleClose}
                    variant="ghost"
                    colorScheme="blue"
                    aria-label="Close notification"
                  />  
                        
                        <Stack direction={{ base: "column", md: "column" }} display="flex" alignContent="center" justifyContent="center" spacing={4}>
                        
                        <Icon as={MdOutlineThumbUpAlt} align="center" fontSize="80" color="droplla.500"  width={{base: "full", md: "full"}}/>
                        <Box display="flex" flexDirection="column" justifyContent="center" fontWeight={190} fontSize={{base: "md", md: "lg"}}> 
                        <p>Great news!</p>
                        <p>You've been added to our waiting list.</p>
                        <p>We can't wait to share what we've been working on. Exciting times ahead!</p>
                        </Box>
                        </Stack>
                    </Box>
                ) : (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack direction={{ base: "column", md: "row" }} mt={8} display="flex" alignContent="center" justifyContent="center" spacing={4}>
                            <Box display="flex" alignContent="center" justifyContent="center">
                                <FormControl mt={.4} isInvalid={errors.email}>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents="none">
                                            <Icon as={MdOutlineMailOutline} color="secondaryGray.600" boxSize={5} />
                                        </InputLeftElement>
                                        <Input
                                            variant='droplla'
                                            type="email"
                                            placeholder="Enter your email"
                                            id='email'
                                            {...register('email', {
                                                required: 'Email is required',
                                                pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: 'Invalid email address' }
                                            })}
                                            autoComplete="email"
                                            width={{ base: "full", md: "350px" }}
                                        />
                                    </InputGroup>
                                    <Box>
                                        <FormErrorMessage fontSize="xs">{errors.email && errors.email.message}</FormErrorMessage>
                                    </Box>
                                </FormControl>
                            </Box>
                            <Box display="flex" alignContent="center" justifyContent="center">
                                <Button variant="droplla" type="submit" isLoading={isSubmitting}>
                                    Notify Me
                                </Button>
                            </Box>
                        </Stack>
                    </form>
                )}


            </Box>

            <Divider mt={8} mb={8} />
        </Box>
    );
};

export default WaitingList;
