import { mode } from "@chakra-ui/theme-tools";
export const inputStyles = {
  components: {
    Input: {
      baseStyle: {
        field: {
          fontWeight: 400,
          borderRadius: "8px",
        },
      },

      variants: {
        main: (props) => ({
          field: {
            bg: mode("transparent", "navy.800")(props),
            border: "1px solid",
            color: mode("secondaryGray.900", "white")(props),
            borderColor: mode("secondaryGray.100", "whiteAlpha.100")(props),
            borderRadius: "md",
            fontSize: "sm",
            p: "20px",
            _placeholder: { color: "secondaryGray.400" },
          },
        }),
        droplla: (props) => ({
          field: {
            fontWeight: "500",
            fontSize: 'md',
            color: mode("navy.700", "white")(props),
            bg: mode("transparent", "transparent")(props),
            border: "1px solid",
            borderColor: mode(
              "secondaryGray.100",
              "rgba(135, 140, 189, 0.3)"
            )(props),
            borderRadius: "md",
            _placeholder: { color: "secondaryGray.600", fontWeight: "400" },
            _focus: {
              borderColor: "blue.500", 
              borderWidth: "2px", 
              boxShadow: "0 0 0 1px blue.500",
            },
            _invalid: { 
              borderColor: "red.500", 
              borderWidth: "2px", 
              boxShadow: "0 0 0 1px red.500", 
            },
          },
        })
      },
    },
    NumberInput: {
      baseStyle: {
        field: {
          fontWeight: 400,
        },
      },

      variants: {
        main: (props) => ({
          field: {
            bg: "transparent",
            border: "1px solid",

            borderColor: "secondaryGray.100",
            borderRadius: "16px",
            _placeholder: { color: "secondaryGray.600" },
          },
        }),
        droplla: (props) => ({
          field: {
            bg: "transparent",
            border: "1px solid",
            fontSize: 'md',
            borderColor: "secondaryGray.100",
            borderRadius: "md",
            _placeholder: { color: "secondaryGray.600" },
          },
        })
      },
    },
    Select: {
      baseStyle: {
        field: {
          fontWeight: 400,
        },
      },

      variants: {
        main: (props) => ({
          field: {
            bg: mode("transparent", "navy.800")(props),
            border: "1px solid",
            color: "secondaryGray.600",
            borderColor: mode("secondaryGray.100", "whiteAlpha.100")(props),
            borderRadius: "md",
            _placeholder: { color: "secondaryGray.600" },
          },
          icon: {
            color: "secondaryGray.600",
          },
        }),
        droplla: (props) => ({
          field: {
            bg: "transparent",
            border: "1px solid",
            fontSize: 'md',
            borderColor: "secondaryGray.100",
            borderRadius: "md",
            _placeholder: { color: "secondaryGray.600" },
          },
        }),
      },
    },
  },
};
