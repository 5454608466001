import { extendTheme } from "@chakra-ui/react";
import { breakpoints } from "./foundations/breakpoints";
import { globalStyles } from "./styles";
import { inputStyles } from "./components/input";
import { buttonStyles } from "./components/button";

const customTheme = extendTheme(
  { breakpoints },
  globalStyles,
  buttonStyles,
  inputStyles,
);

export default customTheme;