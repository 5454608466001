/* global gtag */

const processPathname = (pathname) => {
    const strippedPath = pathname.replace(/^\//, '').trim();

    if (!strippedPath) {
        return 'Landing';
    }

    // Remove trailing slash if present
    const pathWithoutTrailingSlash = strippedPath.replace(/\/$/, '');

    return pathWithoutTrailingSlash.charAt(0).toUpperCase() + pathWithoutTrailingSlash.slice(1).trim();
};

export const logPageView = () => {
    try {
        const processedPathname = processPathname(window.location.pathname);

        gtag('event', 'page_view', {
            page_path: window.location.pathname,
            page_title: processedPathname,
            context: 'Public',
        });
    } catch (error) {
        // gtag is async should handle anyway. This is a saftey measure
    }
};


export const logNavigationClick = (element_location, label) => {
    try {
        gtag('event', 'navigation_click', {
            element_location: element_location,
            element_label: label,
            context: 'Public',
        });
    } catch (error) {
        // gtag is async should handle anyway. This is a saftey measure
    }
};

export const logCtaClick = (element_location, label) => {
    try {
        gtag('event', 'cta_click', {
            element_location: element_location,
            element_label: label,
            context: 'Public',
        });
    } catch (error) {
        // gtag is async should handle anyway. This is a saftey measure
    }
};

export const logRegisterAttempt = (status, http_code) => {
    try {
        gtag('event', 'register_attempt', {
            status: status,
            http_code: http_code,
            context: 'Public',
        });
    } catch (error) {
        // gtag is async should handle anyway. This is a saftey measure
    }
};


export const logloginAttempt = (status, http_code) => {
    try {
        gtag('event', 'login_attempt', {
            status: status,
            http_code: http_code,
            context: 'Public',
        });
    } catch (error) {
        // gtag is async should handle anyway. This is a saftey measure
    }
};


export const logContactUsAttempt = (status, subject) => {
    try {
        gtag('event', 'contact_us_attempt', {
            status: status,
            subject: subject,
            context: 'Public',
        });
    } catch (error) {
        // gtag is async should handle anyway. This is a saftey measure
    }
};

export const logUserProps = (userType) => {
    try {
        gtag('set', 'user_properties', {
            user_type: userType,            
          });
    } catch (error) {
        // gtag is async should handle anyway. This is a saftey measure
    }
};



export const updateConsent = (consentPreferences) => {
    try {
        gtag('consent', 'update', {
            'ad_storage': consentPreferences.marketingCookies ? 'granted' : 'denied',
            'ad_user_data': consentPreferences.marketingCookies ? 'granted' : 'denied',
            'ad_personalization': consentPreferences.marketingCookies ? 'granted' : 'denied',
            'analytics_storage': consentPreferences.performanceCookies ? 'granted' : 'denied',
        });
    } catch (error) {
        // gtag is async should handle anyway. This is a saftey measure
    }
};
