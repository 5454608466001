import React from 'react';
import { Box, Text, Image, Divider, Button} from "@chakra-ui/react";
import { Link } from 'react-router-dom';


const FeatureCard = ({ title, description, imageSrc, link }) => {
    const ImageUrl = `${process.env.PUBLIC_URL}${imageSrc}`;

    return (
        <Box maxW='md' borderColor="secondaryGray.600" borderRadius="md" borderWidth="1px" p={4}
            maxWidth="380px" mb={4} mr={2} ml={2}> 
            <Image borderRadius="md" src={ImageUrl} alt={title} style={{ width: '100%', height: 'auto' }} loading="lazy"/>

            <Divider mt={4} />
            <Text align="left" mt={4} fontSize="lg" fontWeight="bold">{title}</Text>
            <Text color="subtleGray.500" minH="100px" mt={2} mb={4} align="left" fontSize="md" >{description}</Text>
            <Link to={link}>
                <Button colorScheme="blue" variant="outline" borderRadius="md">
                    Explore More
                </Button>
    </Link>
        </Box>
    );
};

export default FeatureCard;
