import { Box, Flex, Heading, Text, useBreakpointValue, SimpleGrid } from "@chakra-ui/react";
import FeatureCard from "./cards/FeatureCard";

const FeatureCards = () => {
  const isMd = useBreakpointValue({ base: false, md: true });

  const featureCardsData = [
    { title: 'Historical Data Insights', description: 'Detailed historical data on each product, including line graphs for listing counts and price changes. Use these insights to make informed decisions and stay ahead of the competition.', imageSrc: '/assets/img/home/features/feature1.webp', link: '/sign-up' },
    { title: 'Smart Profit Calculators', description: 'Interactive profit calculators offer contextual insights using industry benchmarks, ensuring accurate and transparent dropshipping profitability.', imageSrc: '/assets/img/home/features/feature2.webp', link: '/sign-up' },
    { title: 'Product Collections', description: 'Explore compelling product collections like AI Insights, Hidden Gems, and Trending. Easily search and find unique products, giving you a competative edge. ', imageSrc: '/assets/img/home/features/feature3.webp', link: '/sign-up' },
    { title: 'AI-Driven SEO Optimisation', description: "Boost visibility with long-form titles, optimised descriptions, and keyword strategies. From primary keywords to long-tail tags, we've got every angle covered for top search rankings.", imageSrc: '/assets/img/home/features/feature9.webp', link: '/sign-up' },
    { title: 'AI-Crafted Social Media Ads', description: 'AI reviewed products come with social media ad copies, designed for conversion. Choose from different tones and targeting suggestions to boost results instantly.', imageSrc: '/assets/img/home/features/feature7.webp', link: '/sign-up' },
    { title: 'AI-Powered One Click Insights', description: 'Save valuable time on product research with one-click access to Google Trends, Shopping, and more. Instantly explore the AI generated SEO and stay ahead of the competition.', imageSrc: '/assets/img/home/features/feature10.webp', link: '/sign-up' }
  ];


  return (
    <Flex
      bg="#edf3f8"
      _dark={{
        bg: "#3e3e3e",
      }}
      px={{
        base: 0,
        lg: 10,
      }}
      pb={4}
      w="full"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        w="full"
        mt={4}
        py={{
          base: "10",
          lg: "64px",
        }}
        px={{
          base: 4,
          lg: 10,
        }}
        bg="white"
        _dark={{
          bg: "gray.800",
        }}
        rounded="md"
      >
        <Box
          maxW="1700px"
          mx="auto"

        >
          <Box
            textAlign={{
              md: "center",
            }}
          >
            <Heading as="h2"
              _light={{
                color: "droplla.500",
              }}
              fontWeight="semibold"
              textTransform="uppercase"
              letterSpacing="wide"
              fontSize={{
                base: "3xl",
                lg: "4xl",
              }}
            >
              {isMd ? "Product Analytics" : "Analytics"}
            </Heading>
            <Text
              mt={2}
              fontSize={{
                base: "2xl",
                lg: "4xl",
              }}
              lineHeight="8"
              fontWeight="extrabold"
              letterSpacing="tight"
              _light={{
                color: "gray.900",
              }}
            >
              Take Control of Dropshipping
            </Text>
            <Text
              mt={4}

              fontSize={{
                base: "lg",
                lg: "xl",
              }}
              mx={{
                lg: "auto",
              }}
              color="subtleGray.500"
              _dark={{
                color: "gray.400",
              }}
            >
              Gain a competitive edge with Droplla's all-in-one search and analytics tools.
              Uncover winning products, track trends, and save time with powerful platform features.
            </Text>
          </Box>

          <Box mt={10} justifyContent="center" display="flex" >
            <SimpleGrid columns={{base: 1, md: 2, lg: 2, xl: 3 }} spacing={{base: 2, md: 6}}>
              {featureCardsData.map((card, index) => (
                <FeatureCard key={index} {...card} />
              ))}
            </SimpleGrid>

          </Box>
        </Box>
      </Box>
    </Flex>



  );
};

export default FeatureCards;
