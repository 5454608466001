import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';

const CookiesPolicy = () => {
  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    fetch(`/legal/cookies-policy/cookiesPolicy-2024-08-17.html`)
      .then((response) => response.text())
      .then((data) => setHtmlContent(data))
      .catch((error) => console.error("Error loading HTML file:", error));
  }, []);

  return (
    <>
      <Helmet>
        <title>Cookies Policy - droplla</title>
        <meta name="description" content="Learn about the cookies we use and how they enhance your experience on droplla. Understand your choices regarding cookie preferences." />
        <link rel="canonical" href="https://www.droplla.com/legal/cookies-policy" />
      </Helmet>
      <Box pt={8} pb={8} pl={10} pr={10} dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </>
  );
};

export default CookiesPolicy;
