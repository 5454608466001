import { mode } from "@chakra-ui/theme-tools";
export const buttonStyles = {
  components: {
    Button: {
      baseStyle: {
        borderRadius: "16px",
        boxShadow: "45px 76px 113px 7px rgba(112, 144, 176, 0.08)",
        transition: ".25s all ease",
        boxSizing: "border-box",
        _focus: {
          boxShadow: "none",
        },
        _active: {
          boxShadow: "none",
        },
      },
      variants: {
        droplla: (props) => ({
          bg: mode("droplla.500", "droplla.400")(props),
          rounded: "md",
          fontSize: 'md',
          fontWeight: '500',
          color: "white",
          _focus: {
            bg: mode("droplla.500", "droplla.400")(props),
          },
          _active: {
            bg: mode("droplla.500", "droplla.400")(props),
          },
          _hover: {
            bg: mode("droplla.600", "droplla.400")(props),
          },
          _loading: {
            bg: mode("droplla.500", "droplla.400")(props), 
            cursor: "default",
            _hover: {
              bg: mode("droplla.500", "droplla.400")(props),
            },
          },
        }),
      },
    },
  },
};
