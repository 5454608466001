import React from 'react';
import { Box, Flex, Text, Image, Divider, Icon } from "@chakra-ui/react";
import { AiFillFire } from 'react-icons/ai';
import { HiMiniSparkles } from 'react-icons/hi2';
import { FaArrowTrendUp } from 'react-icons/fa6';
import { FaGem, FaStar } from "react-icons/fa";

const icons = {
    HiMiniSparkles: HiMiniSparkles,
    AiFillFire: AiFillFire,
    FaArrowTrendUp: FaArrowTrendUp,
    FaGem: FaGem,
    FaStar: FaStar,
};

const ProductCard = ({ title, imageSrc, reviews, profit }) => {
    const ReviewIcon = icons[reviews.icon];
    const ImageUrl = `${process.env.PUBLIC_URL}${imageSrc}`;
    return (
        <Box maxW='md' borderColor="secondaryGray.600" borderRadius="md" borderWidth="1px" p={4}
            maxWidth="380px" mb={4} mr={4} ml={4} boxShadow='8px 8px 13px rgba(0, 0, 0, 0.2)'> 
            <Image 
            borderRadius="md" 
            src={ImageUrl} 
            alt="Landscape" 
            style={{ width: '100%', height: 'auto' }} 
            loading="lazy"/>

            <Divider mt={4} />
            <Text minH="100px" align="left" mt={4} fontSize="lg" fontWeight="bold">{title}</Text>

            <Flex alignItems="center" mt={4} justify="space-between" >
              <Box
                bg="blue.500"
                color="white"
                borderRadius="md"
                p="4px 8px"
                display="flex"
                alignItems="center"
              >
                {ReviewIcon && <Icon as={ReviewIcon} boxSize={4} />}
                <Text fontWeight="bold" fontSize="md" ml={2}>
                    {reviews.text}
                </Text>
              </Box>
              <Box
                bg="green.500"
                color="white"
                borderRadius="md"
                p="4px 8px"
                display="flex"
                alignItems="center"
              >
                <Text fontWeight="bold" fontSize="md">
                    {profit.text}
                </Text>
              </Box>
            </Flex>
        </Box>
    );
};

export default ProductCard;
