import { ReactComponent as Logo } from 'assets/logo/svg/woo-commerce-logo.svg';
import { Box, useToken } from '@chakra-ui/react';

const WooCommerceLogo = ({ colorKey = 'gray.400', ...props }) => {
  // Use Chakra UI's color token
  const [color] = useToken('colors', [colorKey]);

  return (
    <Box
      as={Logo}
      style={{ fill: color }}
 
      
      {...props}
    />
  );
};

export default WooCommerceLogo;
