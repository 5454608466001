import React from 'react';
import { Outlet } from 'react-router-dom';

import { Box } from '@chakra-ui/react';
import CookieBanner from 'components/cookie-consent/CookieBanner';
import Header from 'components/layout/Header';
import Footer from 'components/layout/Footer';


const LandingLayout = () => {
  return (
    <Box>
      <Header />
      <Outlet />
      <CookieBanner />
      <Footer />
    </Box>
  );
};

export default LandingLayout;
