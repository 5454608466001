import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Fade,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Portal,
  Select,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import { CloseIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import { MdOutlineMailOutline, MdOutlineThumbUpAlt } from 'react-icons/md';
import Logger from '../utils/logger'
import { FaCommentAlt } from 'react-icons/fa';
import { logContactUsAttempt } from 'components/utils/analytics';

const logger = new Logger('FeedbackModal');

const FeedbackModal = ({ isOpen, onClose, onOpen }) => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [submissionError, setSubmissionError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowButton(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setSubmissionError(null);

    const payload = {
      id: uuidv4(),
      kind: 'Public:ContactUs',
      form: data,
      submitted: true,
    };

    try {
      const response = await fetch('/api/users/forms', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      
      if (response.status === 204) {
        logContactUsAttempt("success", data.subject)
      } else {
        logContactUsAttempt("failure", data.subject)
      }

      if (response.status === 204) {
        setIsSubmitted(true)
      } else if (response.status === 403) {
        const errorData = await response.json();
        setSubmissionError(errorData.errorMessage || 'Validation error');
      } else {
        const errorMessage = 'Internal Error - Please try again in a few minutes';
        throw new Error(errorMessage);
      }
    } catch (error) {
      logContactUsAttempt("failure", data.subject)
      logger.error(error)
      setSubmissionError('Internal Error - Please try again in a few minutes');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setIsSubmitted(false);
    onClose();
    reset();
  };

  return (
    <>

      <Fade in={showButton} unmountOnExit="true"
        transition={{
          enter: { duration: 0.3 },
          exit: { duration: 0.3 },
        }}
      >

      <IconButton
          boxSize={12}
          icon={<FaCommentAlt fontSize="24px"/>}
          boxShadow="4px 4px 15px 0px rgba(0, 0, 0, 0.65)"
          aria-label="Contact Us"
          size="lg"
          variant="droplla"
          position="fixed"
          bottom={4}
          right={4}
          borderRadius="xl"
          onClick={onOpen}
          _focus={{
            boxShadow: "4px 4px 15px 0px rgba(0, 0, 0, 0.65)", 
            outline: "none",
          }}
        />
      </Fade>


      <Portal>
        <Fade in={isOpen} unmountOnExit="true">
          <Box
            position="fixed"
            bottom="-4px"
            right={{ base: "0", md: "16px" }}
            m="0"
            borderRadius="md"
            boxShadow="0px -5px 15px -5px rgba(0, 0, 0, 0.3), -5px 0px 15px -5px rgba(0, 0, 0, 0.3), 5px 0px 15px -5px rgba(0, 0, 0, 0.3)"
            width={{ base: "100%", md: "100%" }}
            maxWidth={{ base: "100%", md: "600px" }}
            p="4"
            bg="white"
            zIndex="1400"
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box fontSize="lg" fontWeight="bold" lineHeight="1.2" color="secondaryGray.900">Contact Us</Box>
              <IconButton
                variant="ghost"
                icon={<CloseIcon />}
                onClick={handleClose}
                aria-label="Close"
              />
            </Box>
            <Divider mt={2} mb={2} />

            {isSubmitted ? (
              <Box mt={2} mb={2} minH="476px" textAlign="center" p={8} fontSize="lg" color="droplla.500">
                <Icon as={MdOutlineThumbUpAlt} align="center" fontSize="80" color="droplla.500" width={{ base: "full", md: "full" }} />
                <p>Thank you for contacting droplla.com</p>
                <p>We will endeavour to get back to you within 2-3 business days.</p>
              </Box>

              
            ) : (
              <Box mt={2} mb={2} minH="400px">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Box display="flex" gap={4}>
                    <FormControl mt={4} isInvalid={errors.firstName}>
                      <FormLabel fontSize="sm" color="secondaryGray.900">First Name</FormLabel>
                      <Input
                        variant='droplla'
                        placeholder="Optional"
                        id='firstName'
                        autoComplete="given-name"
                        maxLength={50}
                        {...register('firstName', {
                          validate: {
                            noWhitespace: value => !value || value.trim() !== '' || 'Invalid First Name',
                            minLength: value => !value || value.replace(/\s+/g, '').length >= 2 || 'Invalid First Name',
                            noSymbolsNumbers: value => /^[A-Za-z-]*$/.test(value) || 'First name can only contain letters and hyphens',
                          },
                          maxLength: {
                            value: 50,
                            message: 'First name cannot exceed 50 characters',
                          },
                        })}
                      />
                      <Box>
                        <FormErrorMessage fontSize="xs">{errors.firstName && errors.firstName.message}</FormErrorMessage>
                      </Box>
                    </FormControl>
                    <FormControl mt={4} isInvalid={errors.lastName}>
                      <FormLabel fontSize="sm" color="secondaryGray.900">Last Name</FormLabel>
                      <Input
                        variant='droplla'
                        placeholder="Optional"
                        id='lastName'
                        autoComplete="family-name"
                        maxLength={50}
                        {...register('lastName', {
                          validate: {
                            noWhitespace: value => !value || value.trim() !== '' || 'Invalid Last Name',
                            minLength: value => !value || value.replace(/\s+/g, '').length >= 2 || 'Invalid Last Name',
                            noSymbolsNumbers: value => /^[A-Za-z-]*$/.test(value) || 'Last name can only contain letters and hyphens',
                          },
                          maxLength: {
                            value: 50,
                            message: 'Last name cannot exceed 50 characters',
                          },
                        })}
                      />
                      <Box>
                        <FormErrorMessage fontSize="xs">{errors.lastName && errors.lastName.message}</FormErrorMessage>
                      </Box>
                    </FormControl>
                  </Box>
                  <FormControl mt={4} isInvalid={errors.email} isRequired>
                    <FormLabel fontSize="sm" color="secondaryGray.900">Email</FormLabel>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <Icon as={MdOutlineMailOutline} color="secondaryGray.600" boxSize={5} />
                      </InputLeftElement>
                      <Input
                        variant='droplla'
                        type="email"
                        placeholder="Email@provider.com"
                        id='email'
                        {...register('email', {
                          required: 'Email is required',
                          pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: 'Invalid email address' }
                        })}
                        autoComplete="email"
                      />
                    </InputGroup>
                    <Box>
                      <FormErrorMessage fontSize="xs">{errors.email && errors.email.message}</FormErrorMessage>
                    </Box>
                  </FormControl>
                  <FormControl mt={4} isRequired>
                    <FormLabel fontSize="sm" color="secondaryGray.900">Subject</FormLabel>
                    <Select placeholder="Select..." {...register('subject')} color="gray.600">
                      <option value="technical-issue">Technical Issue</option>
                      <option value="account-management">Account Management</option>
                      <option value="feature-request">Feature Request</option>
                      <option value="collaboration">Collaboration</option>
                      <option value="custom-pricing">Custom Pricing</option>
                      <option value="help">Help</option>
                      <option value="other">Other</option>
                    </Select>
                  </FormControl>
                  <FormControl mt={4} isInvalid={errors.message} isRequired>
                    <FormLabel fontSize="sm" color="secondaryGray.900">Message</FormLabel>
                    <Textarea
                      _placeholder={{ color: "secondaryGray.600" }}
                      fontWeight="500"
                      fontSize='md'
                      color="navy.700"
                      placeholder="Write your message here..."
                      minH="120px"
                      {...register('message', {
                        required: "Message is required",
                        minLength: {
                          value: 10,
                          message: "Message must be at least 10 characters"
                        },
                        maxLength: {
                          value: 3000,
                          message: "Message cannot exceed 3000 characters"
                        }
                      })}
                    />
                    <Box>
                      <FormErrorMessage fontSize="xs">{errors.message && errors.message.message}</FormErrorMessage>
                    </Box>
                  </FormControl>

                  {submissionError && (
                    <Text color="red.500" mb={4} fontSize="xs">
                      {submissionError}
                    </Text>
                  )}

                  <Box display="flex" justifyContent="flex-end" mt={4}>
                    <Button variant="droplla" type="submit" p={5} isLoading={isSubmitting}>Send</Button>
                  </Box>
                </form>
              </Box>
            )}
          </Box>
        </Fade>
      </Portal>
    </>
  );
};

export default FeedbackModal;
