import Cookies from 'js-cookie';

function useAuth() {

  const checkSignInStatus = () => {
    const isAuthenticated = !!Cookies.get('client-auth');
    if (isAuthenticated) {
      redirectToDashboard();
    }
    return isAuthenticated;
  }

  const signIn = (value, expiryIso8601) => {
    let expiryDate;
    try {
      expiryDate = new Date(expiryIso8601);
    } catch (error) {
      const now = new Date();
      now.setDate(now.getDate() + 7);
      expiryDate = now;
    }

    Cookies.set('client-auth', true, {
      sameSite: 'strict',
      expires: expiryDate
    });
    redirectToDashboard();
  };


  const redirectToDashboard = () => {
    window.location.href = `${window.location.origin}/dashboard/home`;
  };

  return { checkSignInStatus, signIn };
}


export default useAuth;
