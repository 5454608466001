import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";


// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Icon,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import DefaultAuth from '../layout/Default';

import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

import Logger from '../../../components/utils/logger'
import { useNavigate, useSearchParams } from "react-router-dom";
const logger = new Logger('ResetPassword');

const illustration = `${process.env.PUBLIC_URL}/assets/img/auth/droplla-side-banner.png`;

function ResetPassword() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "subtleGray.500";

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);


  const [submissionError, setSubmissionError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasValidToken, setHasValidToken] = useState(false);

  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");

  const navigateToReset = useCallback((invalidToken = false) => {
    navigate('/request-password-reset', { state: { invalidToken } });
  }, [navigate]);

  useEffect(() => {
    setIsSubmitting(true);
  
    if (!token) {
      setHasValidToken(false);
      navigateToReset();
    } else {
      const checkToken = async () => {
        try {
          const response = await fetch('/api/auth/email-profile/password-reset-check', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
          });

          if (response.status === 204) {
            setHasValidToken(true);
          } else {
            setHasValidToken(false);
            navigateToReset(true);     
          }
        } catch (error) {
          setHasValidToken(false);
          logger.error(error);
          navigateToReset(true);
        } finally {
          setIsSubmitting(false);
        }
      };

      checkToken();
    }
  }, [token, navigateToReset]);


  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setSubmissionError(null);

    const payload = {
      token: token,
      password: data.password,
    };

    try {
      const response = await fetch('/api/auth/email-profile/password-reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (response.status === 204) {
        navigate('/reset-password-success');
      } else {
        setSubmissionError(`We've unable to reset your password at this time  - Please try again in a few minutes`);
      }
    } catch (error) {
      logger.error(error);
      setSubmissionError(`We've unable to reset your password at this time  - Please try again in a few minutes`);
    } finally {
      setIsSubmitting(false);
    }
  };

  if(!hasValidToken) {
    return null;
  }

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        flexDirection='column'
      >
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
           Reset Password
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            w={{ base: "100%", md: "420px" }}
            color={textColorSecondary}
            fontWeight='400'
            fontSize='lg'
            flexWrap="wrap"
          >
            Please enter your new password and confirm it below to reset your droplla account password.
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={errors.password} isRequired>
              <FormLabel
                ms='4px'
                fontSize='md'
                fontWeight='500'
                color='textColor'
                display='flex'
                htmlFor='password'
              >
                New Password
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  placeholder='Min. 8 characters'
                  mb={1}
                  size='lg'
                  type={show ? "text" : "password"}
                  variant='droplla'
                  id='password'
                  {...register('password', {
                    required: 'Password is required',
                    validate: {
                      minLengthExcludingWhitespace: value => value.replace(/\s/g, '').length >= 8 || 'Password must be at least 8 characters excluding whitespace'
                    }
                  })}
                  autoComplete="new-password"
                />
                <InputRightElement display='flex' alignItems='center' mt='5px'>
                  <Icon
                    color='textColorSecondary'
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              <Box mb={4}>
                <FormErrorMessage fontSize="xs">{errors.password && errors.password.message}</FormErrorMessage>
              </Box>
            </FormControl>

            <FormControl isInvalid={errors.confirmPassword} isRequired>
              <FormLabel
                ms='4px'
                fontSize='md'
                fontWeight='500'
                color='textColor'
                display='flex'
                htmlFor='confirmPassword'
              >
                Confirm New Password
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  placeholder='Min. 8 characters'
                  mb={1}
                  size='lg'
                  type={show ? "text" : "password"}
                  variant='droplla'
                  id='confirmPassword'
                  {...register('confirmPassword', {
                    validate: value => value === watch('password') || 'Passwords must match'
                  })}
                  autoComplete="new-password"
                />
                <InputRightElement display='flex' alignItems='center' mt='5px'>
                  <Icon
                    color='textColorSecondary'
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              <Box mb={4}>
                <FormErrorMessage fontSize="xs">{errors.confirmPassword && errors.confirmPassword.message}</FormErrorMessage>
              </Box>
            </FormControl>

            {submissionError && (
              <Text color="red.500" mb={4} fontSize="xs">
                {submissionError}
              </Text>
            )}

            <Button
              w='100%'
              h='50'
              mb='24px'
              variant="droplla"
              type="submit"
              mt={2}
              isLoading={isSubmitting}
            >
              Change Password
            </Button>
          </form>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default ResetPassword;
