import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Text, Slide, useDisclosure, IconButton, Fade } from '@chakra-ui/react';
import { FaCookieBite } from 'react-icons/fa';
import Cookies from 'js-cookie';
import CookieModal from './CookieModel';
import { v4 as uuidv4 } from 'uuid';
import { updateConsent } from '../utils/analytics';


const CURRENT_COOKIE_CONSENT_VERSION = process.env.REACT_APP_COOKIE_CONSENT_VERSION;

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userInteracted, setUserInteracted] = useState(false);

  const [consentId] = useState(() => {
    const cookie = Cookies.get('cookiePreferences');
    if (cookie) {
      const preferences = JSON.parse(cookie);
      return preferences.consentId || uuidv4();
    } else {
      return uuidv4();
    }
  });

  const [cookiePreferences, setCookiePreferences] = useState({
    strictlyNecessary: true,
    performanceCookies: true,
    functionalCookies: true,
    marketingCookies: true,
    socialMediaCookies: true,
    version: CURRENT_COOKIE_CONSENT_VERSION
  });

  useEffect(() => {
    const savedPreferences = Cookies.get('cookiePreferences');
    if (savedPreferences) {
      const parsedPreferences = JSON.parse(savedPreferences);

      if (parsedPreferences.version === CURRENT_COOKIE_CONSENT_VERSION) {
        setCookiePreferences(parsedPreferences);
        updateConsent(parsedPreferences);
      } else {
        setShowBanner(true);
      }
    } else {
      setShowBanner(true);
    }
  }, []);


  const saveCookiePreferences = useCallback(() => {
    return new Promise((resolve) => {
      const currentTime = new Date().toISOString();
      const preferencesWithVersion = {
        ...cookiePreferences,
        form: "Public",
        version: CURRENT_COOKIE_CONSENT_VERSION,
        consentId: consentId,
        updateAt: currentTime
      };
      Cookies.set('cookiePreferences', JSON.stringify(preferencesWithVersion), { 
        expires: 365,
        sameSite: 'strict' 
      });
      updateConsent(preferencesWithVersion);
      resolve();
    });
  }, [cookiePreferences, consentId]);

  useEffect(() => {
    if (userInteracted) {
      saveCookiePreferences().then(() => {
        (async () => {
          try {
            await fetch('/api/users/preferences', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include', 
            });
          } catch (error) {
            console.error('Error with GET request:', error);
          }
        })();
      });
    }
  }, [userInteracted, saveCookiePreferences]);

  const handleAcceptAll = () => {
    setCookiePreferences({
      strictlyNecessary: true,
      performanceCookies: true,
      functionalCookies: true,
      marketingCookies: true,
      socialMediaCookies: true,
    });
    setUserInteracted(true);
    handleOnClose();
  };

const handleOnClose = () => {
    onClose();
    setShowBanner(false);
  };


  return (
    <>
      <Slide direction="bottom" in={showBanner} style={{ zIndex: 10 }}>
        <Box
          bottom={0}
          left={0}
          right={0}
          bg="blue.500"
          color="white"
          p={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          boxShadow="0px -2px 8px rgba(0, 0, 0, 0.5)"
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Box display="flex" alignItems="center">
            <Box minW="24px">
              <FaCookieBite color="white" size="30px" />
            </Box>
            <Text ml={{ base: 4, md: 4 }} mr={{ base: 0, md: 2 }} fontSize="sm"> By clicking “Accept All”, you agree to the storing of cookies on your device to enhance and analyze site usage, and assist in our marketing efforts.</Text>
          </Box>
          <Box>
            <Button
              mt={{ base: 4, md: 0 }}
              fontSize={{ base: "sm", lg: "md" }}
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              px={4}
              py={3}
              fontWeight="bold"
              rounded="md"
              color="blue.500"
              background="white"
              onClick={handleAcceptAll}
            >
              Accept All
            </Button>
            <Button
              ml={2}
              mt={{ base: 4, md: 0 }}
              fontSize={{ base: "sm", lg: "md" }}
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              px={4}
              py={3}
              fontWeight="bold"
              rounded="md"
              color="blue.500"
              background="white"
              onClick={onOpen}
            >
              Settings
            </Button>
          </Box>
        </Box>
      </Slide>

        <Fade in={!showBanner} unmountOnExit="true"
          transition={{
            enter: { duration: 0.5 },
            exit: { duration: 0.5 },
          }}
        >
          <IconButton
            boxSize={12}
            icon={<FaCookieBite fontSize="27px" />}
            boxShadow="4px 4px 15px 0px rgba(0, 0, 0, 0.65)"
            aria-label="Contact Us"
            size="lg"
            variant="droplla"
            position="fixed"
            bottom={4}
            left={4}
            zIndex={10}
            borderRadius="xl"
            onClick={onOpen}
            _focus={{
              boxShadow: "4px 4px 15px 0px rgba(0, 0, 0, 0.65)",
              outline: "none",
            }}
          />
        </Fade>
 

      <CookieModal isOpen={isOpen} onClose={onClose} handleOnClose={handleOnClose} handleAcceptAll={handleAcceptAll} cookiePreferences={cookiePreferences} setCookiePreferences={setCookiePreferences} setUserInteracted={setUserInteracted} />

    </>
  );
};

export default CookieBanner;
