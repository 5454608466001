import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Heading, Text, Button, Flex, Stack, Divider, Icon } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import DefaultAuth from './../layout/Default'
import { FaCheck } from 'react-icons/fa';

function Success() {
    const location = useLocation();
    const { state } = location;
    const message = state?.message || "";

    const illustration = `${process.env.PUBLIC_URL}/assets/img/auth/droplla-side-banner.png`;

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: "100%", "md": "max-content" }}
                w='100%'
                mx={{ base: "auto", lg: "0px" }}
                me='auto'
                h='100%'
                alignItems='start'
                justifyContent='center'
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}

                flexDirection='column'>


                <Box

                    borderWidth="1px"
                    borderColor="gray.200"
                    borderRadius="xl"
                    p={4}
                    ml={{ base: 0, lg: 0 }}
                    mr={{ base: 0, lg: 0 }}
                    mt={4}
                >

<Flex alignItems="flex-start" w={{ base: "100%", md: "420px" }}>
  <Stack direction="column" spacing={4}>
    <Flex alignItems="center">
      <Icon as={FaCheck} mr={3} color="green.500" boxSize={{ base: 6, md: 8 }} />
      <Heading color="secondaryGray.900" as="h2" size={{ base: "md", md: "lg" }}>
        Registration Successful
      </Heading>
    </Flex>
    <Divider mt={2} mb={4} />
    <Text color="secondaryGray.900" fontSize="md" mb={2} pl={0} whiteSpace="normal" wordBreak="break-word">
      {message} - Thank you for registering.
    </Text>
    <Text color="secondaryGray.900" fontSize="md" fontWeight="bold" mb={4} pl={0} whiteSpace="normal" wordBreak="break-word">
      A verification link has been sent to your email. Please follow the instructions to complete your profile.
    </Text>
    <Link to='/sign-in'>
      <Button w='100%' h='50' variant="droplla" type="submit" mb={2}>
        Sign In
      </Button>
    </Link>
  </Stack>
</Flex>
                </Box>
            </Flex>
        </DefaultAuth>
    );
}

export default Success;
