import { CheckIcon } from "@chakra-ui/icons";
import { Box, Flex, Image, Heading, Text, Button, Icon, SimpleGrid } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { logCtaClick } from "../../../components/utils/analytics";
import { useEffect } from "react";
import ShopifyLogo from "assets/logo/shopifyLogo";
import AmazonLogo from "assets/logo/amazonLogo";
import EbayLogo from "assets/logo/ebayLogo";
import WooCommerceLogo from "assets/logo/wooCommerceLogo";

const infographicUrl = `${process.env.PUBLIC_URL}/assets/img/home/droplla-info-graphic-ex-wide-nobk-900w.webp`;
const ctaBackgroundUrl = `${process.env.PUBLIC_URL}/assets/img/home/cta-background.svg`;

const handleLinkEventLog = (label) => {
  logCtaClick('CallToAction', label);
};



const CallToAction = () => {

  useEffect(() => {

    const link = document.createElement('link');
    link.rel = 'preload';
    link.href = infographicUrl;
    link.as = 'image';
    link.type = 'image/webp';
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return (
    <Flex
      bg="#edf3f8"
      _dark={{
        bg: "#3e3e3e",
      }}
      px={{
        base: 0,

      }}
      pb={4}
      w="full"

    >
      <Box
        w="full"
        py="0px"
        px={{
          base: 4,
          lg: 6,
        }}
        bg="white"
        _dark={{
          bg: "gray.800",
        }}
        rounded="md"
      >
        <Box maxW="1900px"
          mx="auto" py={{ base: 8, lg: "50px" }}  >
            <Box height="100%" w="100%" backgroundImage={ctaBackgroundUrl}
              backgroundSize="cover"
              backgroundPosition="center" 
              backgroundRepeat="no-repeat">
          <SimpleGrid
            columns={{
              base: 1,
              lg: 2,
            }}

            spacingX={{
              base: 10,
              lg: 10,
            }}
                      >
            <Box
            >

              <Text
                mb={4}
                fontSize={{
                  base: "sm",
                }}
                fontWeight="bold"
                color="subtleGray.500"
                _dark={{
                  color: "gray.200",
                }}
                align={{ base: "center", lg: "left" }}
                              >
                SMART DROPSHIPPING POWERED BY AI
              </Text>
              <Heading as='h1'
                fontSize={{
                  base: "4xl",
                  md: "40px",
                  "3xl": "6xl"
                }}
                fontWeight="extrabold"
                lineHeight="1.2"
                color="droplla.500"
                _dark={{
                  color: "gray.100",
                }}
                mb={6}
                
              >
                <Text display="block" align={{ base: "center", lg: "left" }}>Ready to find winning products?</Text>




                <Text
                  display={{ base: 'none', lg: 'block' }}
                  fontSize={{
                    base: "3xl",
                    md: "35px",
                    "3xl": "5xl"
                  }}
                  color="subtleGray.500"
                  _dark={{
                    color: "gray.200",
                  }}
                   
                >
                  Get started for free.
                </Text>
              </Heading>
              <Text
                mb={6}
                fontSize={{
                  base: "lg",
                  lg: "xl",
                }}
                color="subtleGray.500"
                _dark={{
                  color: "gray.200",
                }}
                align={{ base: "center", lg: "left" }}
              >
                Powerful Real-Time Insights and AI-Powered Product Analytics.
              </Text>

              <Link to="/sign-up" w="">
                <Button

                  alignItems="center"
                  justifyContent="center"
                  px={8}
                  py={{ base: 6, lg: 7 }}
                  border="solid transparent"
                  rounded="md" shadow="md" w={{ base: "100%", lg: "250px" }}
                  variant="droplla"
                  fontSize="xl"
                  onClick={() => handleLinkEventLog('Sign up for free')}
                >
                  Sign up for free
                </Button>
              </Link>


              <Flex
                direction={{
                  base: "row",
                  sm: "row",
                }}
                wrap="wrap"
                spacing={4}
                mt={5}
                justifyContent={{
                  base: "center",
                  lg: "flex-start",
                }}
                alignItems={{
                  base: "center",
                  lg: "flex-start",
                }}
                textAlign={{
                  base: "center",
                  lg: "left",
                }}
              >
                <Box alignItems="center" mb={8} mr={6} display={{ base: 'none', lg: 'flex' }}>
                  <Icon as={CheckIcon} color="droplla.500" boxSize={4} mr={2} />
                  <Text fontSize="md" color="subtleGray.500">Instant Access</Text>
                </Box>
                <Box alignItems="center" mb={8} mr={6} display={{ base: 'none', lg: 'flex' }}>
                  <Icon as={CheckIcon} color="droplla.500" boxSize={4} mr={2} />
                  <Text fontSize="md" color="subtleGray.500">Cancel Anytime</Text>
                </Box>
                <Box alignItems="center" mb={8} mr={6} display={{ base: 'none', lg: 'flex' }} >
                  <Icon as={CheckIcon} color="droplla.500" boxSize={4} mr={2} />
                  <Text fontSize="md" color="subtleGray.500">AI Powered</Text>
                </Box>
                <Box alignItems="center" mb={8} mr={6} display={{ base: 'none', lg: 'flex' }} >
                  <Icon as={CheckIcon} color="droplla.500" boxSize={4} mr={2} />
                  <Text fontSize="md" color="subtleGray.500">1 Million+ Products</Text>
                </Box>
              </Flex>
            </Box>
            <Box
              w={{
                base: "100%"
              }}
              mx="auto"
              textAlign="center"
              alignContent="center"
              alignItems="center"
            >
              <Image
                p={{ base: 0, lg: 2 }}
                mt={{ base: 4, lg: 0 }}
                mx="auto"
                src={infographicUrl}
                alt="Infographic showing the results of an AI product review"
                loading="lazy"
              />
            </Box>
          </SimpleGrid>
          <Flex justifyContent="center" alignItems="cetner" align="center" mt={14} mb={8}>
   
            <Text px={4} w="450px" align="center" color="gray.400" fontWeight="bold">FOR ALL SELLERS</Text>

          </Flex>
          </Box>

          <Flex
            justifyContent="center"
            flexWrap="wrap"
            flexDirection="row"
          >
            <Box w={{ base: "35%", lg: "220px" }} mb={{ base: "4", lg: "0" }}>
              <ShopifyLogo />
            </Box>
            <Box w={{ base: "35%", lg: "220px" }} mb={{ base: "4", lg: "0" }}>
              <AmazonLogo />
            </Box>
            <Box w={{ base: "35%", lg: "220px" }} mb={{ base: "4", lg: "0" }}>
              <EbayLogo />
            </Box>
            <Box w={{ base: "35%", lg: "220px" }} mb={{ base: "4", lg: "0" }}>
              <WooCommerceLogo />
            </Box>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default CallToAction;
