import { Box, Flex, SimpleGrid, Heading, Text, Image, Divider, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { ArrowForwardIcon } from "@chakra-ui/icons";
import { logCtaClick } from "../../../components/utils/analytics";

const Features = () => {

  const screenshotSearchBaseUrl = `${process.env.PUBLIC_URL}/assets/img/home/features/screenshot-search`;
  const screenshotBadgesBaseUrl = `${process.env.PUBLIC_URL}/assets/img/home/features/screenshot-badges`;


  const handleLinkEventLog = (label) => {
    logCtaClick('Features', label);
  };

  return (
    <Flex
      bg="#edf3f8"
      _dark={{
        bg: "#3e3e3e",
      }}
      px={{
        base: 0,
        lg: 10,
      }}
      pb={4}
      w="full"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        w="full"
        py="0px"
        px={{
          base: 4,
          lg: 10,
        }}
        bg="white"
        _dark={{
          bg: "gray.800",
        }}
        rounded="md"
      >
        <Box maxW="1700px"
          mx="auto" py="50px">
          <SimpleGrid
            alignItems="center"
            columns={{
              base: 1,
              md: 2,
            }}
            mb="60px"
            spacingY={{
              base: 15,
              lg: 32,
            }}
            spacingX={{
              base: 10,
              lg: 24,
            }}
          >
            <Box
            >
              <Heading as='h2'
                mb={4}
                fontSize={{
                  base: "3xl",
                  lg: "4xl",
                }}
                fontWeight="extrabold"
                textAlign={{
                  base: "left",
                  md: "left",
                }}
                color="droplla.500"
                _dark={{
                  color: "gray.400",
                }}
                lineHeight="shorter"
              >
                Powerful Search & Filtering
              </Heading>
              <Divider mb={4} width="100px" borderWidth="5px" borderColor="droplla.500"
                opacity={1} />
              <Text
                mb={2}
                textAlign={{
                  base: "left",
                  md: "left",
                }}
                color="subtleGray.500"
                _dark={{
                  color: "gray.400",
                }}
                fontSize={{
                  md: "xl",
                }}
              >
                Maximise your dropshipping potential anywhere, anytime.
                Our lightning-fast product analytics tool is both mobile and desktop friendly.<br /><br />
                Discover profitable products with ease and tailor your search with powerful filters across 1 million+ products.

              </Text>
              <Link to="/sign-up">
                <Button
                  mt={6}
                  variant="outline"
                  fontSize="lg"
                  display="inline-flex"
                  alignItems="center"

                  px={8}
                  py={6}
                  fontWeight="bold"
                  rounded="md"
                  color="droplla.500"
                  background="white"
                  rightIcon={<ArrowForwardIcon />}
                  onClick={() => handleLinkEventLog('Powerful Search')}
                  w={{ base: "full", lg: "280px" }}
                >
                  Get Started For Free
                </Button>
              </Link>
            </Box>


            <Image
              src={`${screenshotSearchBaseUrl}-809w.webp`} // Default image
              alt="Visualizing the powerful search options for exploring the dropshipping products"
              loading="lazy"
            />


          </SimpleGrid>
          <SimpleGrid
            alignItems="center"
            columns={{
              base: 1,
              xl: 2,
            }}
            flexDirection="column-reverse"

            spacingY={{
              base: 15,
              xl: 32,
            }}
            spacingX={{
              base: 10,
              xl: 24,
            }}
          >
            <Box
              order={{
                base: "initial",
                xl: 2,
              }}
            >
              <Heading as='h2'
                mb={4}
                fontSize={{
                  base: "3xl",
                  xl: "4xl",
                }}
                fontWeight="extrabold"
                textAlign={{
                  base: "left",
                  xl: "left",
                }}
                color="droplla.500"
                _dark={{
                  color: "gray.400",
                }}
                lineHeight="shorter"
              >
                Visually Instant Product Insights
              </Heading>
              <Divider mb={4} width="100px" borderWidth="5px" borderColor="droplla.500"
                opacity={1} />
              <Text
                mb={2}
                textAlign={{
                  base: "left",
                  md: "left",
                }}
                color="subtleGray.500"
                _dark={{
                  color: "gray.400",
                }}
                fontSize={{
                  xl: "xl",
                }}
              >
                Streamline your product selection process with color-coded visual badges. <br /><br />
                Our advanced algorithms and AI have analyzed thousands of products to highlight their strengths and weaknesses,
                so you can quickly identify winners and minimize risks.
              </Text>
              <Link to="/sign-up">
                <Button
                  mt={6}
                  variant="outline"
                  fontSize="lg"
                  display="inline-flex"
                  alignItems="center"
                  w={{ base: "full", lg: "280px" }}
                  px={8}
                  py={6}
                  fontWeight="bold"
                  rounded="md"
                  color="droplla.500"
                  background="white"
                  rightIcon={<ArrowForwardIcon />}
                  onClick={() => handleLinkEventLog('Product Insights')}
                >
                  Get Started For Free
                </Button>
              </Link>
            </Box>
            <Image
              src={`${screenshotBadgesBaseUrl}-809w.webp`}
              alt="Visualizing the meta data attached dropshipping products for quick insights"
              loading="lazy"
            />
          </SimpleGrid>
        </Box>
      </Box>
    </Flex>


  );
};

export default Features;
