import React from 'react';
import { AccordionItem, AccordionButton, AccordionPanel, Switch, Box } from '@chakra-ui/react';

const CookiePreferenceAccordion = ({ title, description, checked, disabled, onChange }) => {
  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left" color="secondaryGray.900">
            {title}
          </Box>
          <Switch
            size="lg"
            disabled={disabled}
            onChange={(e) => onChange(e.target.checked)}
            isChecked={checked}
          />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4} fontSize="sm" color="subtleGray.500">
        {description}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default CookiePreferenceAccordion;
