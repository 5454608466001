import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';


const DataProcessing = () => {
  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    fetch(`/legal/data-processing-agreement/dpa-2024-09-03.html`)
      .then((response) => response.text())
      .then((data) => setHtmlContent(data))
      .catch((error) => console.error("Error loading HTML file:", error));
  }, []);

  return (
    <>
      <Helmet>
        <title>Data Processing Agreement - droplla</title>
        <meta name="description" content="Understand how droplla processes your data, your rights, and our commitments to data privacy and security." />
        <link rel="canonical" href="https://www.droplla.com/legal/cookies-policy" />
      </Helmet>
      <Box pt={8} pb={8} pl={10} pr={10} dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </>

  );
};

export default DataProcessing;