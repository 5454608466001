import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { logPageView, logUserProps } from '../utils/analytics';

const GAWrapper = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    logUserProps("Visitor");
  }, []);

  useEffect(() => {
    logPageView();
  }, [location]);

  return <>{children}</>;
};

export default GAWrapper;