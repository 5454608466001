import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Heading, Text, Flex, Stack, Divider, Icon } from '@chakra-ui/react';
import DefaultAuth from './../layout/Default'
import { FaCheck } from 'react-icons/fa';

function RequestPasswordResetSucess() {
    const location = useLocation();
    const { state } = location;
    const message = state?.message || "";
    const navigate = useNavigate();

    const illustration = `${process.env.PUBLIC_URL}/assets/img/auth/droplla-side-banner.png`;

    useEffect(() => {
        if (!message) {
            navigate('/request-password-reset');
        }
    }, [message, navigate]);

    if (!message) {
        return null;
    }



    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: "100%", "md": "max-content" }}
                w='100%'
                mx={{ base: "auto", lg: "0px" }}
                me='auto'
                h='100%'
                alignItems='start'
                justifyContent='center'
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}

                flexDirection='column'>


                <Box

                    borderWidth="1px"
                    borderColor="gray.200"
                    borderRadius="xl"
                    p={4}
                    ml={{ base: 0, lg: 0 }}
                    mr={{ base: 0, lg: 0 }}
                    mt={4}
                >

                    <Flex alignItems="flex-start" w={{ base: "100%", md: "420px" }}>
                        <Stack direction="column" spacing={3} >
                            <Flex alignItems="center">
                                <Icon as={FaCheck} mr={3} color="green.500" boxSize={{ base: 6, md: 8 }}  />

                                <Heading color="secondaryGray.900" as="h2" size={{ base: "md", md: "lg" }}>
                                    Email Sent
                                </Heading>
                            </Flex>
                            <Divider mt={2} mb={4} />
                            <Text
                                color="secondaryGray.900"
                                fontSize="md"
                                marginBottom={2}
                                pl={{ base: 0, lg: 0 }}
                                whiteSpace="normal"
                                wordBreak="break-word"
                            > Password reset email sent to: <strong>{message}</strong></Text>
                            <Text
                                color="secondaryGray.900"
                                fontSize="md"

                                fontWeight="bold"
                                marginBottom={2}
                                pl={{ base: 0, lg: 0 }}
                                whiteSpace="normal"
                                wordBreak="break-word"
                            > Please check your inbox and spam folder for the email and follow the instructions.</Text>


                        </Stack>
                    </Flex>
                </Box>
            </Flex>
        </DefaultAuth>
    );
}

export default RequestPasswordResetSucess;
