import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Box, Heading, Text, Flex, Stack, Divider, Icon, Button, Spinner } from '@chakra-ui/react';
import DefaultAuth from './../layout/Default'
import { MdOutlineEmail } from 'react-icons/md';

function VerifyEmail() {
    const illustration = `${process.env.PUBLIC_URL}/assets/img/auth/droplla-side-banner.png`;

    const [searchParams] = useSearchParams();
    const [tokenValid, setTokenValid] = useState(null);
    const [token, setToken] = useState(null);

    const tokenParam = searchParams.get("token");

    useEffect(() => {

        if (tokenParam) {
            setToken(tokenParam)
        }
    }, [tokenParam]);

    useEffect(() => {
        if (token) {
            const checkToken = async () => {
                try {
                    const response = await fetch('/api/auth/email-profile/verify-email', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ token }),
                    });

                    if (response.status === 204 || response.status === 200) {
                        setTokenValid(true);
                    } else {
                        setTokenValid(false);
                    }
                } catch (error) {
                    setTokenValid(false);
                }
            };

            checkToken();

        }
    }, [token]);

    const headerColor = tokenValid === null
        ? "gray.400"
        : tokenValid
            ? "green.500"
            : "red.500";

    const iconColor = tokenValid === null
        ? "gray.400"
        : tokenValid
            ? "green.500"
            : "red.500";

    const header = tokenValid === null
        ? "Verifying Email..."
        : tokenValid
            ? "Email Verified"
            : "Email Verification Failed";

    const punchline = tokenValid === null
        ? "Verifying."
        : tokenValid
            ? "Your email address has been successfully verified."
            : "We failed to valid your email verification link.";

    const message = tokenValid === null
        ? "Verifying."
        : tokenValid
            ? "You’re all set to fully explore droplla.com. Your AI-powered dropshipping journey awaits!"
            : "Try copying the link again from your verification email, or head over to your user profile to send a new verification email and link.";

    const buttonRoute = tokenValid === null
        ? "/sign-in"
        : tokenValid
            ? "/sign-in"
            : "/dashboard/settings";

    const buttonText = tokenValid === null
        ? "Loading."
        : tokenValid
            ? "Start Exploring"
            : "User Profile";

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: "100%", "md": "max-content" }}
                w='100%'
                mx={{ base: "auto", lg: "0px" }}
                me='auto'
                h='100%'
                alignItems='start'
                justifyContent='center'
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}

                flexDirection='column'>

                <Box
                    borderWidth="1px"
                    borderColor="gray.200"
                    borderRadius="xl"
                    p={4}
                    ml={{ base: 0, lg: 0 }}
                    mr={{ base: 0, lg: 0 }}
                    mt={4}
                    width="100%"
                >
                    <Flex alignItems="flex-start" w={{ base: "100%", md: "420px" }} >
                        <Stack direction="column" spacing={3} w="100%" >
                            <Flex alignItems="center">
                                <Icon as={MdOutlineEmail} mr={3} color={iconColor} boxSize={{ base: 8, md: 10 }} />

                                <Heading color={headerColor} as="h2" size={{ base: "md", md: "lg" }}>
                                    {header}
                                </Heading>
                            </Flex>
                            <Divider mt={2} mb={4} />
                            {tokenValid === null ? (
                                <Flex justifyContent="center" alignItems="center">
                                    <Spinner
                                        thickness="4px"
                                        speed="0.75s"
                                        emptyColor="gray.200"
                                        color="blue.500"
                                        size="xl"
                                        mb={4}
                                    />
                                </Flex>
                            ) : (
                                <Box>

                                    <Text
                                        color="secondaryGray.900"
                                        fontSize="md"
                                        marginBottom={4}
                                        whiteSpace="normal"
                                        wordBreak="break-word"
                                    >
                                        {punchline}
                                    </Text>
                                    <Text
                                        color="secondaryGray.900"
                                        fontSize="md"
                                        fontWeight="bold"
                                        whiteSpace="normal"
                                        wordBreak="break-word"
                                    >
                                        {message}
                                    </Text>

                                    <Link to={buttonRoute}>
                                        <Button
                                            w='100%'
                                            h='50'
                                            variant="droplla"
                                            type="submit"
                                            mb={2}
                                            mt={8}
                                        >
                                            {buttonText}
                                        </Button>
                                    </Link>
                                </Box>


                            )}
                        </Stack>
                    </Flex>
                </Box>
            </Flex>
        </DefaultAuth>
    );
}

export default VerifyEmail;
