import { Box, Stack, Icon, Flex, HStack, VStack, Text, Divider, useDisclosure } from "@chakra-ui/react";
import { GrInstagram } from 'react-icons/gr';
import { FaXTwitter } from "react-icons/fa6";
import { scroller } from 'react-scroll';
import { Link as ChakraLink } from '@chakra-ui/react'
import { Link as LinkRouter, useLocation, useNavigate } from "react-router-dom";
import { logNavigationClick } from '../utils/analytics';


import FeedbackModal from "components/feedback/Feedback";
import { useEffect } from "react";
import DropllaLogo from "assets/logo/dropllaLogo";
import { FiLinkedin } from "react-icons/fi";

const Footer = () => {
  const navigate = useNavigate();

  const handleNavLinkEventLog = (label) => {
    logNavigationClick('Footer', label);
  };

  const redirectToBlog = () => {
    window.location.href = `${window.location.origin}/blog`;
  };


  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleNavigation = (sectionId) => {
    navigate(`/#${sectionId}`);
  };

  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const sectionId = hash.replace('#', '');
      scroller.scrollTo(sectionId, {
        smooth: true,
        duration: 800,
        offset: -50,
      });
    }
  }, [hash]);

  return (
    <Flex
      bg="#0A083C"
      _dark={{
        bg: "#3e3e3e",
      }}
      px={{
        base: 0,
        md: 10,
      }}
      pb={4}
      w="full"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        maxW="1700px"
        bg="#0A083C"
        w="full"
        _dark={{
          bg: "#0A083C",
        }}
      >
        <Stack
          direction={{
            base: "column",
            lg: "row",
          }}

          w="full"
          justify="space-between"
          py={{
            base: 4,
            md: 10,
          }}
          px={{
            base: 3,
            md: 10,
          }}
        >
          <Flex justify="center">
            <DropllaLogo
              w={{
                base: "120px",
                lg: "200px",
              }}
              mb={{base: 2, lg: 0}}
              colorKey="white" />
          </Flex>
          <HStack
            alignItems="start"
            flex={1}
            justify="space-around"
            fontSize={{
              base: "16px",
              md: "16px",
            }}
            color="gray.100"
            _dark={{
              color: "white",
            }}
            textAlign={{
              base: "left",
              md: "left",
            }}
          >
            <Flex justify="start" direction="column">
              <Text mb={3} fontWeight="800">Company</Text>
              <Text color="secondaryGray.700" _hover={{ color: 'white', cursor: 'pointer' }} onClick={() => { handleNavLinkEventLog('Home'); handleNavigation('home'); }}>Home</Text>
              <Text color="secondaryGray.700" _hover={{ color: 'white', cursor: 'pointer' }} onClick={() => { handleNavLinkEventLog('Features'); handleNavigation('features'); }}>Features</Text>
              <Text color="secondaryGray.700" _hover={{ color: 'white', cursor: 'pointer' }} onClick={() => {handleNavLinkEventLog('Blog'); redirectToBlog();}}>Blog</Text>

              {/* <Text color="secondaryGray.700" _hover={{ color: 'white', cursor: 'pointer' }} onClick={() => { handleNavLinkEventLog('Blog'); handleNavigation('blog'); }}>Blog</Text> */}
            </Flex>
            <Flex justify="start" direction="column" display={{base: "none", md: "flex"}}>
              <Text mb={3} fontWeight="800">Support</Text>
              <Text color="secondaryGray.700" _hover={{ color: 'white', cursor: 'pointer' }} onClick={() => { handleNavLinkEventLog('FAQ'); handleNavigation('faq'); }}>FAQ</Text>
              <Text color="secondaryGray.700" _hover={{ color: 'white', cursor: 'pointer' }} onClick={onOpen}>Contact Us</Text>
            </Flex>
            <Flex justify="start" direction="column">
              <Text mb={3} fontWeight="800">Legal</Text>
              <LinkRouter to='/legal/terms-of-use' ><Text color="secondaryGray.700" _hover={{ color: 'white' }} onClick={() => handleNavLinkEventLog('Terms of Use')}>Terms of Use</Text></LinkRouter>
              <LinkRouter to='/legal/privacy-policy' ><Text color="secondaryGray.700" _hover={{ color: 'white' }} onClick={() => handleNavLinkEventLog('Privacy Policy')}>Privacy Policy</Text></LinkRouter>
              <LinkRouter to='/legal/cookies-policy' ><Text color="secondaryGray.700" _hover={{ color: 'white' }} onClick={() => handleNavLinkEventLog('Cookies Policy')}>Cookies Policy</Text></LinkRouter>
              <LinkRouter to='/legal/data-processing' ><Text color="secondaryGray.700" _hover={{ color: 'white' }} onClick={() => handleNavLinkEventLog('Data Processing')}>Data Processing</Text></LinkRouter>
            </Flex>
          </HStack>
        </Stack>
        <Divider
          w="95%"
          mx="auto"
          color="gray.600"
          _dark={{
            color: "#F9FAFB",
          }}
          h="3.5px"
        />
        <VStack py={3}>
          <HStack justify="center" color="gray.100">
            <ChakraLink
              href="https://www.instagram.com/droplla_official/"
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <Icon
                color="gray.100"
                _dark={{
                  color: "white",
                }}
                h="20px"
                w="20px"
                as={GrInstagram}
              />
            </ChakraLink>
            <ChakraLink
              href="https://x.com/droplla_com"
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <Icon
                color="gray.100"
                _dark={{
                  color: "white",
                }}
                h="20px"
                w="20px"
                as={FaXTwitter}
              />
            </ChakraLink>
            <ChakraLink
              href="https://linkedin.com/company/droplla"
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
            >
              <Icon
                color="gray.100"
                _dark={{
                  color: "white",
                }}
                h="20px"
                w="20px"
                as={FiLinkedin}
              />
            </ChakraLink>
          </HStack>

          <Text
            textAlign="center"
            fontSize="smaller"
            fontWeight='500'
            color="gray.100"
            _dark={{
              color: "white",
            }}
          >
            &copy; {1900 + new Date().getYear()} Droplla.com - All Rights Reserved.
          </Text>
        </VStack>
      </Box>
      <FeedbackModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
    </Flex>
  );
};

export default Footer;
