import React, { useEffect, useState } from 'react';
import { Button, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Accordion, Flex, Divider, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { Link as LinkRouter } from "react-router-dom";
import { Link as ChakraLink } from '@chakra-ui/react';
import CookiePreferenceAccordion from './CookiePreferenceAccordian';

const CookieModal = ({ isOpen, onClose, handleOnClose, handleAcceptAll, cookiePreferences, setCookiePreferences, setUserInteracted }) => {
  const [strictlyNecessary, setStrictlyNecessary] = useState(cookiePreferences.strictlyNecessary);
  const [performanceCookies, setPerformanceCookies] = useState(cookiePreferences.performanceCookies);
  const [functionalCookies, setFunctionalCookies] = useState(cookiePreferences.performanceCookies);
  const [marketingCookies, setMarketingCookies] = useState(cookiePreferences.performanceCookies);
  const [socialMediaCookies, setSocialMediaCookies] = useState(cookiePreferences.performanceCookies);

  useEffect(() => {
    setStrictlyNecessary(cookiePreferences.strictlyNecessary);
    setPerformanceCookies(cookiePreferences.performanceCookies);
    setFunctionalCookies(cookiePreferences.functionalCookies);
    setMarketingCookies(cookiePreferences.marketingCookies);
    setSocialMediaCookies(cookiePreferences.socialMediaCookies);
  }, [cookiePreferences]);

  const wrapperAcceptAll = () => {
    setPerformanceCookies(true);
    setFunctionalCookies(true);
    setMarketingCookies(true);
    setSocialMediaCookies(true);
    handleAcceptAll();
  }

  const handleRejectAll = () => {
    setPerformanceCookies(false);
    setFunctionalCookies(false);
    setMarketingCookies(false);
    setSocialMediaCookies(false);

    setCookiePreferences((prevPreferences) => ({
        ...prevPreferences,
        performanceCookies: false,
        functionalCookies: false,
        marketingCookies: false,
        socialMediaCookies: false,
    }));
    setUserInteracted(true);
    handleOnClose();
};

  const handleSavePreferences = () => {
    setCookiePreferences({ strictlyNecessary, performanceCookies, functionalCookies, marketingCookies, socialMediaCookies });
    setUserInteracted(true);
    handleOnClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="secondaryGray.900">Privacy Preferences <Divider mt={3} /></ModalHeader>
        <ModalBody>
        <Tabs colorScheme="blue" mt={-4} >
            <TabList>
              <Tab>Declaration</Tab>
              <Tab>About</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Text fontSize="sm" color="secondaryGray.900">
                  This website uses cookies to enhance your experience, personalise content and ads, provide social media features, and analyse our traffic. Some cookies are necessary for the site to function, while others help us improve our services and display relevant advertising. We may share information about your use of our site with our social media, advertising, and analytics partners. You can choose which cookies to allow, and you can change your preferences at any time. For more details, see our
                  <LinkRouter to='/legal/cookies-policy' target="_blank" rel="noopener noreferrer">
                    <ChakraLink ml={1} mr={1} color="blue.500" textDecoration="underline" _hover={{ textDecoration: 'underline', cursor: 'pointer' }}>Cookies Policy</ChakraLink>
                  </LinkRouter>
                  and
                  <LinkRouter to='/legal/privacy-policy' target="_blank" rel="noopener noreferrer">
                    <ChakraLink ml={1} color="blue.500" textDecoration="underline" _hover={{ textDecoration: 'underline', cursor: 'pointer' }}>Privacy Policy</ChakraLink>
                  </LinkRouter>.
                </Text>
              </TabPanel>
              <TabPanel>
                <Text fontSize="sm" color="secondaryGray.900">
                  Cookies are small text files placed on your device by websites you visit. They are used to make websites work more efficiently and provide additional features. Essential cookies are necessary for the website to operate correctly and cannot be turned off. Other cookies help enhance your browsing experience by remembering your preferences, enabling personalised content, and allowing targeted advertising. While essential cookies are always active, you have the option to enable or disable non-essential cookies.
                </Text>
              </TabPanel>
            </TabPanels>
          </Tabs>

          <Button colorScheme="blue" borderRadius="md" mt={2} onClick={wrapperAcceptAll}>Accept All</Button>
          <Accordion mt={4} allowMultiple>
            <CookiePreferenceAccordion
              title="Strictly Necessary Cookies"
              description="These cookies are crucial for the proper functioning of our platform and cannot be disabled. They are typically set in response to actions you take, such as setting your privacy preferences, logging in, or filling out forms. While you can configure your browser to block or notify you about these cookies, some parts of the platform may not work as intended. These cookies do not store any personal information."
              checked={strictlyNecessary}
              onChange={setStrictlyNecessary}
              disabled
            />
            <CookiePreferenceAccordion
              title="Performance Cookies"
              description="These cookies are used to analyze and improve the performance of our platform. They enable us to track visits and traffic sources, understand which pages are most and least popular, and see how users interact with our platform. The information collected is aggregated and anonymized, helping us make data-driven improvements. If you choose to disable these cookies, we won’t be able to collect performance data and our ability to optimise the platform may be limited."
              checked={performanceCookies}
              onChange={setPerformanceCookies}
            />
            <CookiePreferenceAccordion
              title="Functional Cookies"
              description="These cookies enhance the functionality and personalisation of our platform. They may be set by us or by third-party services integrated into our platform. Disabling these cookies may affect the performance of some features or services, potentially limiting their functionality or personalisation."
              checked={functionalCookies}
              onChange={setFunctionalCookies}
            />
            <CookiePreferenceAccordion
              title="Marketing Cookies"
              description="These cookies may be set through our site by our advertising partners. They are used to build a profile of your interests and display relevant adverts on other websites. Although these cookies do not store personal information directly, they uniquely identify your browser and device. If you choose not to allow these cookies, you may see less relevant advertising."
              checked={marketingCookies}
              onChange={setMarketingCookies}
            />
            <CookiePreferenceAccordion
              title="Social Media Cookies"
              description="These cookies may be set through our site by our social media partners. They are used to build a profile of your interests and display relevant adverts on other websites. While these cookies do not store personal information directly, they uniquely identify your browser and device. If you choose not to allow these cookies, you may see less targeted advertising."
              checked={socialMediaCookies}
              onChange={setSocialMediaCookies}
            />
          </Accordion>
          <Flex justifyContent="flex-end">
            <Button size="sm" variant="outline" colorScheme="blue" borderRadius="md" mt={4} mr={4} onClick={handleRejectAll}>
              Reject All
            </Button>
            <Button size="sm" variant="outline" colorScheme="blue" borderRadius="md" mt={4} onClick={handleSavePreferences}>Save Preferences</Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CookieModal;
