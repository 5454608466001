// utils/logger.js
import config from '../../config/config';

const logLevels = {
    debug: 3,
    info: 2,
    warn: 1,
    error: 0
};

const getUserAndBrowserInfo = () => {
    const userInfo = {};
    try {
        userInfo.userAgent = navigator.userAgent;
    } catch (error) {
        userInfo.userAgent = `Error: ${error.message}`;
    }
    try {
        userInfo.platform = navigator.platform;
    } catch (error) {
        userInfo.platform = `Error: ${error.message}`;
    }
    try {
        userInfo.language = navigator.language;
    } catch (error) {
        userInfo.language = `Error: ${error.message}`;
    }
    try {
        userInfo.screenResolution = `${window.screen.width}x${window.screen.height}`;
    } catch (error) {
        userInfo.screenResolution = `Error: ${error.message}`;
    }
    try {
        userInfo.windowSize = `${window.innerWidth}x${window.innerHeight}`;
    } catch (error) {
        userInfo.windowSize = `Error: ${error.message}`;
    }
    try {
        userInfo.browserName = navigator.userAgent.match(/(firefox|msie|chrome|safari|trident)/gi)?.[0] || 'unknown';
    } catch (error) {
        userInfo.browserName = `Error: ${error.message}`;
    }
    try {
        userInfo.cookiesEnabled = navigator.cookieEnabled;
    } catch (error) {
        userInfo.cookiesEnabled = `Error: ${error.message}`;
    }
    try {
        userInfo.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (error) {
        userInfo.timezone = `Error: ${error.message}`;
    }
    try {
        userInfo.referrer = document.referrer;
    } catch (error) {
        userInfo.referrer = `Error: ${error.message}`;
    }
    try {
        userInfo.connectionType = navigator.connection ? navigator.connection.effectiveType : 'unknown';
    } catch (error) {
        userInfo.connectionType = `Error: ${error.message}`;
    }
    return userInfo;
};

class Logger {
    constructor(fileName) {
        this.logLevel = logLevels[config.logLevel] || logLevels.debug;
        this.fileName = fileName || 'UnknownFile';
    }

    logMessage(level, message, stack = null) {
        if (logLevels[level] <= this.logLevel) {
            let log = {
                level: level,
                fileName: this.fileName,
                message: message,
                timestamp: new Date().toISOString()
            };
            if (level === 'error') {
                log.userInfo = getUserAndBrowserInfo();
                log.stack = stack;
            }
            console[level](JSON.stringify(log));
        }
    }

    debug(message) {
        this.logMessage('debug', message);
    }

    info(message) {
        this.logMessage('info', message);
    }

    warn(message) {
        this.logMessage('warn', message);
    }

    error(message) {
        const error = new Error(message);
        this.logMessage('error', message, error.stack);
    }
}

export default Logger;
