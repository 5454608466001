import { Box, Flex, Heading, Text, List, ListIcon, ListItem, Image } from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";

const AICard = ({ title, description, points, imageSrc }) => {
  return (
    <Box
      maxW="1400px"
      width="100%"
      borderRadius="md"
      bgColor="blue.50"
      mb={4}
      ml={2}
      mr={2}
    >
      <Flex
        direction={{ base: 'column', xl: 'row' }}
        width="100%"
      >
        <Box
          width={{ base: '100%', xl: '50%' }}
          p={{
            base: 4,
            md: 8,
          }}
        >
          <Text
            color="secondaryGray.900"
            fontSize={{base: "2xl", md: "3xl"}}
            fontWeight='700'
            marginBottom={5}
          >
            {title}
            
          </Text>
  
          <Text
            color="secondaryGray.900"
            fontSize={{base: "md", md: "lg"}}
            fontWeight='300'
            marginBottom={8}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <List spacing={8}>
            {points.map((point, index) => (
              <ListItem key={index}>
                <Flex alignItems="center">
                  <ListIcon as={FaCheckCircle} color='blue.500' marginRight={4} marginLeft={2} fontSize={24} />
                  <Heading
                    fontSize="xl"
                    fontWeight="bold"
                    lineHeight="6"
                    _light={{
                      color: "blue.500",
                    }}
                  >
                    {point.title}
                    <Text
                      color="secondaryGray.900"
                      fontSize="md"
                      fontWeight='300'
                      display={{ base: 'none', lg: 'block' }}
                    >

                      {point.description}
                    </Text>
                  </Heading>
                </Flex>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box
          width={{ base: '100%', xl: '50%' }}
          display={{ base: 'none', xl: 'block' }}
        >
          <Box
            height="100%"
            bg="white"
            borderRadius="md"
            overflow="hidden"
          >
            <Image
              src={imageSrc}
              borderRadius="md"
              height="100%"
              width="100%"
              objectFit="cover"
              objectPosition="center"
              alt={`Visualizing the AI results for ${title}`}
              loading="lazy"
            />
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default AICard;
