import { Box, Flex, Heading, Text, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel } from "@chakra-ui/react";
import { Link as LinkRouter } from "react-router-dom";

const FAQ = () => {
  return (
    <Flex
      bg="#edf3f8"
      _dark={{
        bg: "#3e3e3e",
      }}
      px={{
        base: 0,
        lg: 10,
      }}
      pb={4}
      w="full"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        w="full"
        mt={4}
        py={{
          base: "10",
          lg: "64px",
        }}
        px={{
          base: 4,
          lg: 10,
        }}
        bg="white"
        _dark={{
          bg: "gray.800",
        }}
        rounded="md"
      >
        <Box
          maxW="1700px"
          mx="auto"
          px={{
            base: 0,
            lg: 8,
          }}
        >
          <Box
            textAlign={{
              lg: "center",
            }}
          >
            <Heading as="h2"
              _light={{
                color: "droplla.500",
              }}
              fontWeight="semibold"
              textTransform="uppercase"
              letterSpacing="wide"
              fontSize={{
                base: "3xl",
                lg: "4xl",
              }}
            >
              FAQ
            </Heading>
            <Text
              mt={2}
              fontSize={{
                base: "2xl",
                lg: "3xl",
              }}
              lineHeight="8"
              fontWeight="extrabold"
              letterSpacing="tight"
              _light={{
                color: "gray.900",
              }}
            >
              Your Questions Answered
            </Text>
          </Box>

          <Box mt={10}>
            <Accordion allowToggle>
              <AccordionItem>
                <h2>
                  <AccordionButton sx={{ '&:focus': { boxShadow: 'none !important' } }}>
                    <Box as='span' flex='1' textAlign='left' color="droplla.700" fontWeight={500}>
                      What is Droplla ?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} color="gray.600">
                  Droplla is your ultimate dropshipping / e-commerce companion, offering powerful real-time insights and AI-driven product analytics. With fast search and filtering across thousands of products, our AI bots provide actionable insights, optimized titles, SEO suggestions, and competitor analysis. Droplla also delivers tailored ad copy, social media strategies, and reliable data to help you discover winning products and make informed decisions effortlessly.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton sx={{ '&:focus': { boxShadow: 'none !important' } }}>
                    <Box as='span' flex='1' textAlign='left' color="droplla.700" fontWeight={500}>
                      Does the Droplla platform work on a mobile device ?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} color="gray.600">
                  Absolutely! Droplla is designed to be accessible across various devices including mobile phones, tablets, and desktop computers.
                  You can seamlessly access our platform regardless of the device you're using.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
              <h2>
                <AccordionButton sx={{ '&:focus': { boxShadow: 'none !important' } }}>
                  <Box as='span' flex='1' textAlign='left' color="droplla.700" fontWeight={500}>
                    Is there a free trial?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} color="gray.600">
                We offer a Free plan, so there's no need for a trial! You can get started right away and upgrade at any time if you need more features.
              </AccordionPanel>
            </AccordionItem>



              <AccordionItem>
                <h2>
                  <AccordionButton sx={{ '&:focus': { boxShadow: 'none !important' } }}>
                    <Box as='span' flex='1' textAlign='left' color="droplla.700" fontWeight={500}>
                      What makes Droplla so powerful ?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} color="gray.600">
                  <Text mb={6}>Droplla's power lies in its ability to operate at a massive scale while delivering comprehensive data analysis. With thousands of products and millions of historical data points, our platform meticulously examines each product's strengths and weaknesses, offering users a clear, visual understanding of their market position.</Text>

                  <Text>At the heart of Droplla are our AI-trained bots, which continuously run in-depth analysis reports on tens of thousands of top products. These bots provide a wealth of actionable insights, including optimized product titles and descriptions, detailed competitor analysis, and SEO suggestions. Additionally, Droplla offers tailored ad copy, social media creatives, and targeted social media strategies, all designed to enhance visibility and drive engagement. Users can even request priority analysis for products not yet covered, ensuring they always have access to the most relevant data. The true power of Droplla is in the scale and precision of these AI bots, enabling users to make informed, data-driven decisions, maximize their marketing efforts, and achieve superior market performance.</Text>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton sx={{ '&:focus': { boxShadow: 'none !important' } }}>
                    <Box as='span' flex='1' textAlign='left' color="droplla.700" fontWeight={500}>
                      How can Droplla help my store?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} color="gray.600">
                  <Text mb={6}>Droplla can significantly enhance your store’s performance by providing you with powerful tools and insights to optimize your dropshipping business. Here’s how: </Text>
                  <Text ml={6} mb={6}>
                    <ul>
                      <li><strong>Discover Winning Products:</strong> Our AI-driven analytics identify high-potential products and market trends, helping you choose the best items to sell.</li>
                      <li><strong>Optimize Listings:</strong> Get actionable SEO suggestions, optimized product titles, and compelling descriptions to improve visibility and attract more customers.</li>
                      <li><strong>Enhance Marketing:</strong> Leverage tailored ad copy, social media strategies, and creative assets to drive targeted traffic and boost engagement.</li>
                      <li><strong>Market Analysis:</strong> Gain valuable insights into market trends and competitive dynamics, allowing you to strategically position your products and capitalize on emerging opportunities.</li>
                      <li><strong>Make Data-Driven Decisions:</strong> Access comprehensive data and real-time analytics to make informed decisions, manage risks, and maximize profitability.</li>
                    </ul>
                  </Text>
                  By using Droplla, you can streamline your operations, increase your store’s visibility, and make strategic decisions that drive growth and success.
                </AccordionPanel>
              </AccordionItem>


              <AccordionItem>
                <h2>
                  <AccordionButton sx={{ '&:focus': { boxShadow: 'none !important' } }}>
                    <Box as='span' flex='1' textAlign='left' color="droplla.700" fontWeight={500}>
                      Can I request AI analysis for a specific product?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} color="gray.600">
                  Yes, you can request an analysis for a specific product. While Droplla’s AI-trained bots continuously analyze tens of thousands of top products, you may have specific products that are not yet covered. With our priority analysis option, you can request a detailed analysis, and our bots will typically pick up and respond to your request within a few minutes. You’ll receive actionable insights, optimized titles, SEO suggestions, and competitor analysis tailored to the product you’ve specified.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton sx={{ '&:focus': { boxShadow: 'none !important' } }}>
                    <Box as='span' flex='1' textAlign='left' color="droplla.700" fontWeight={500}>
                      Do I need to have technical skills to use Droplla ?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  Not at all! Droplla is built to be accessible and user-friendly for everyone.
                  Whether you're a novice or an experienced dropshipper,
                  our platform's clean and simple user experience is designed to make finding profitable products effortless for all levels of users.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton sx={{ '&:focus': { boxShadow: 'none !important' } }}>
                    <Box as='span' flex='1' textAlign='left' color="droplla.700" fontWeight={500}>
                      How often do you update the products in Droplla ?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} color="gray.600">
                  We receive thousands of updates every day to ensure that thousands of products remain synchronized with our suppliers.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton sx={{ '&:focus': { boxShadow: 'none !important' } }}>
                    <Box as='span' flex='1' textAlign='left' color="droplla.700" fontWeight={500}>
                      Can I use Droplla on any platform or marketplace?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} color="gray.600">
                  Yes, Droplla is platform agnostic, meaning you can use our data and tools on any platform or marketplace. Whether your store is on Shopify, WooCommerce, or you sell on TikTok, Facebook, Amazon, or Etsy, the data can be used anywhere. Our ad creative examples are optimized for Meta but can be adapted for use anywhere. Additionally, our long-form and short-form copy is versatile, perfect for both social media and email marketing, giving you the flexibility to apply our insights wherever you do business.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton sx={{ '&:focus': { boxShadow: 'none !important' } }}>
                    <Box as='span' flex='1' textAlign='left' color="droplla.700" fontWeight={500}>
                      Does Droplla provide product fulfillment services ?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} color="gray.600">
                  No, Droplla doesn't directly provide product fulfillment services. Instead, we consolidate thousands of products from various suppliers into one convenient platform.
                  Users can easily search for and find profitable products, and we empower them to identify and mitigate risks associated with certain products.
                  We provide relaiable links back to the suppliers or platforms capable of fulfilling the product orders.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton sx={{ '&:focus': { boxShadow: 'none !important' } }}>
                    <Box as='span' flex='1' textAlign='left' color="droplla.700" fontWeight={500}>
                      Can I easily cancel my subscription?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} color="gray.600">
                  Absolutely, you're not tied down by any long-term commitments once you sign up. If you decide to cancel, your subscription will remain active until the end of the current billing cycle. Please note that due to the digital nature of our data access, we do not offer refunds. No additional charges will be incurred after the billing cycle ends.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton sx={{ '&:focus': { boxShadow: 'none !important' } }}>
                    <Box as='span' flex='1' textAlign='left' color="droplla.700" fontWeight={500}>
                      Which plan is best for me ?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} color="gray.600">
                  <b>Free Plan:</b> Great for exploring Droplla’s core features. However, it has significant limitations, and advanced functionalities like custom AI requests are restricted.
                  <p /><p /><b>Basic Plan:</b> Ideal for new dropshippers or entrepreneurs just getting started. This plan offers the full platform experience but is best suited for small-scale operations, such as single-product stores.
                  <p /><p /><b>Standard Plan:</b> Our most popular option. Designed for mid-sized ecommerce businesses, this plan is perfect for those serious about product analysis and scaling their operations. It's ideal for medium-sized store owners who prioritise detailed insights.
                  <p /><p /><b>Pro Plan:</b> Tailored for large store owners and experienced dropshippers. This plan is geared towards those looking to scale their product analysis and store management efforts. It’s the best choice for users wanting to fully leverage Droplla’s capabilities.
                  <p /><p /><b>Expert Plan:</b> Designed for enterprise clients and multi-store owners. This plan is built for large-scale businesses and teams, offering comprehensive support for expansive operations. It’s the ideal solution for organisations looking to maximise their ecommerce potential.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
              <h2>
                <AccordionButton sx={{ '&:focus': { boxShadow: 'none !important' } }}>
                  <Box as='span' flex='1' textAlign='left' color="droplla.700" fontWeight={500}>
                    Is Droplla safe and secure to use?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} color="gray.600">
                <Text mb={6}>Yes, Droplla is safe and secure to use. We take your security very seriously by implementing modern security practices, ensuring that your sensitive information is always protected:</Text>
               <Text ml={6} mb={6}>
                <ul>
                  <li><strong>SSL Encryption:</strong> We use SSL encryption to secure all data transmitted between your browser and our servers.</li>
                  <li><strong>Secure Information Handling:</strong> We employ advanced security measures to encrypt and safeguard any sensitive information you provide.</li>
                  <li><strong>Payment Security:</strong> We do not store payment details. Instead, we use trusted third-party vendors like Stripe for all payment processing, ensuring your financial information is handled securely.</li>
                  <li><strong>Minimal Data Storage:</strong> We only require minimal information, such as your email address and password, to create your account. Alternatively, you can choose to log in using third-party social platforms like Google or Apple, further limiting the data we store.</li>
                </ul>
                </Text>
                With droplla, you can trust that your data is secure, and your privacy is respected. For more detail please see our
                <LinkRouter to='/legal/privacy-policy'>
                    <Text ml={1} color="blue.500" textDecoration="underline" _hover={{ textDecoration: 'underline', cursor: 'pointer' }}>Privacy Policy</Text>
                  </LinkRouter>
              </AccordionPanel>
            </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton sx={{ '&:focus': { boxShadow: 'none !important' } }}>
                    <Box as='span' flex='1' textAlign='left' color="droplla.700" fontWeight={500}>
                      Does Droplla offer multi-user access?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} color="gray.600">
                  Yes, Droplla offers multi-user access, allowing businesses and teams to collaborate efficiently on the platform. For custom pricing and to discuss the best plan for your team's needs, please reach out to us directly. We’ll help you set up a solution that fits your requirements.</AccordionPanel>
              </AccordionItem>

            </Accordion>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default FAQ;
