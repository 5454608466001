import React, { useRef } from 'react';
import Slider from 'react-slick';
import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import ProductData from '../variables/ProductData.json';
import ProductCard from './cards/ProductCard';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { logCtaClick } from "../../../components/utils/analytics";

const ProductSlideSection = () => {

  const handleLinkEventLog = (label) => {
    logCtaClick('ProductSlideSection', label);
  };

  function ProductSlide({ index, ...otherProps }) {
    return (
      <div {...otherProps}>
        <ProductCard {...ProductData[index]} />
      </div>
    );
  }

  const sliderRef = useRef();


  var settings = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Flex
      bg="#edf3f8"

      _dark={{
        bg: "#3e3e3e",
      }}
      px={{
        base: 0,
        lg: 10,
      }}
      pb={4}
      w="full"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        w="full"
        py={{
          base: "10",
          lg: "64px",
        }}
        px={{
          base: 4,
          lg: 10,
        }}
        bg="white"
        _dark={{
          bg: "gray.800",
        }}
        rounded="md"
        position="relative"
      >

        <Box maxW="1700px"
          mx="auto" >
          <Box
            textAlign={{
              md: "center",
            }}
          >
            <Heading as="h2" mb={2}
              fontSize={{
                base: "3xl",
                lg: "4xl",
              }}
              fontWeight="bold" lineHeight="tight">
              Product Collections
            </Heading>
            <Text
              mb={6}
              fontSize={{
                base: "lg",
                lg: "xl",
              }}
              color="subtleGray.500"
              _dark={{
                color: "gray.400",
              }}
            >
              Jump ahead of the competition with our algorithm-powered product picks
            </Text>
          </Box>

          <Slider {...settings} ref={sliderRef}>
            {ProductData.map((product, index) => (
              <ProductSlide key={index} index={index} />
            ))}
          </Slider>

          <Box
            textAlign="center"
          >
            <Link to="/sign-up">
              <Button
                mt={6}
                variant="outline"
                fontSize="lg"
                display="inline-flex"
                alignItems="center"
                w={{base: "full", lg: "280px"}}
                px={8}
                py={6}
                fontWeight="bold"
                rounded="md"
                color="droplla.500"
                background="white"
                rightIcon={<ArrowForwardIcon />}
                onClick={() => handleLinkEventLog('See More Products')}
              >
                See More Products
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Flex >
  );
};

export default ProductSlideSection;
