import { Box, VStack } from "@chakra-ui/react";
import { Element } from 'react-scroll';

import CallToAction from "./components/CallToAction";
import Features from "./components/Features";
import HeroSection from "./components/HeroSection";
import HeroSectionLower from "./components/HeroSectionLower";
import Pricing from "./components/Pricing";
import FAQ from "./components/FAQ";
// import Blog from "./components/Blog";
import AiShowcaseSection from "./components/AIShowcaseSection";
import ProductSlideSection from "./components/ProductSlideSection";
import FeatureCards from "./components/FeatureCards";

const LandingPage = () => {
  return (
    <Box>
      <Box
        bg="white"
        float='right'
        minHeight='100vh'
        height='100%'
        position='relative'
        w='100%'>
          
        <VStack spacing={0}>
          <Element name="home" style={{ width: '100vw', margin: 0, padding: 0 }}></Element>
          <CallToAction />
   
          <Element id="features" name="features" style={{ width: '100vw', margin: 0, padding: 0 }}>
              <Features />
          </Element>
          <AiShowcaseSection />
          <FeatureCards />
          <ProductSlideSection />
          <HeroSection />
          {/* <Element name="pricing" style={{ width: '100vw', margin: 0, padding: 0 }}>
            <Pricing />
          </Element>
          <HeroSectionLower />
          <Element name="blog" style={{ width: '100vw', margin: 0, padding: 0 }}>
            <Blog />
          </Element> */}
          <Element name="faq" style={{ width: '100vw', margin: 0, padding: 0 }}>
            <FAQ />
          </Element>
        </VStack>
      </Box>
    </Box>
  );
};

export default LandingPage;
