import { Box, Flex, Heading, Text, Icon, Button } from "@chakra-ui/react";
import { HiMiniSparkles } from "react-icons/hi2";
import Slider from 'react-slick';
import AICard from "./cards/AiCard"
import { useEffect, useRef, useState } from "react";

const aiCardAnalysis = `${process.env.PUBLIC_URL}/assets/img/home/features/AI Descriptions - ai card 1 -700w.webp`;
const aiCardSEO = `${process.env.PUBLIC_URL}/assets/img/home/features/AI Descriptions - ai card 2 -700w.webp`;
const aiCardMarketing = `${process.env.PUBLIC_URL}/assets/img/home/features/AI Descriptions - ai card 3 -700w.webp`;

const AIShowcaseSection = () => {

  const [currentSlide, setCurrentSlide] = useState(0);
  const [isSliderTransitioning, setIsSliderTransitioning] = useState(false);
  const sliderRef = useRef();

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 14000,
    pauseOnHover: true,
    arrows: false,
    afterChange: (current) => {
      setCurrentSlide(current);
      setIsSliderTransitioning(false);
    },
    beforeChange: (oldIndex, newIndex) => {
      setIsSliderTransitioning(true)
      setCurrentSlide(newIndex);
    },
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isSliderTransitioning) {
        sliderRef.current.slickNext();
      }
    }, settings.autoplaySpeed);

    return () => clearInterval(interval);
  }, [currentSlide, isSliderTransitioning, settings.autoplaySpeed]);

  const goToSlide = (index) => {
    if (!isSliderTransitioning) {
      sliderRef.current.slickGoTo(index);
      setCurrentSlide(index);
    }
  };

  const cardsData = [
    {
      title: "AI-Powered Analysis",
      description: "Gain <b>actionable insights</b> by leveraging our comprehensive AI reports, and strategically position your products <b>for optimal market performance.</b>",
      points: [
        {
          title: "In-depth Product Analysis",
          description: "Access real-time insights on market potential, financial viability, and product differentiation, generated by our AI for thousands of products.",
        },
        {
          title: "Enhanced Titles & Descriptions",
          description: "Instantly utilize optimized titles, 3% keyword density descriptions, and SEO elements like tags, meta descriptions, and rich URLs to boost visibility.",
        },
        {
          title: "Competitor Analysis",
          description: "Gain an edge with detailed competitor analysis, highlighting strengths, weaknesses and opportunities, allowing you to strategically position your products in the market.",
        },
        {
          title: "Data-Driven Decisions",
          description: "Make informed, strategic decisions using our AI's comprehensive analysis and big data intelligence, giving you a competitive edge.",
        },
      ],
      imageSrc: aiCardAnalysis,
    },
    {
      title: "AI-Optimized Products",
      description: "Boost search rankings, <b>enhance customer interest</b>, and drive targeted traffic with AI-powered SEO, compelling copy, and <b>smart social media strategies.</b>",
      points: [
        {
          title: "SEO Optimization",
          description: "Instantly enhance search engine ranking with AI-generated long-tail keywords, meta descriptions, and URL suggestions.",
        },
        {
          title: "Natural Mentions & Hashtags",
          description: "Leverage AI-identified natural mentions and relevant hashtags to improve social media reach and engagement.",
        },
        {
          title: "Long-Tail Keyword Generation",
          description: "Utilize AI-generated long-tail keywords that drive targeted traffic to your listings.",
        },
        {
          "title": "Tag and Keyword Analysis",
          "description": "Effortlessly research tags, social media keywords, and search terms with integrated links to Google Analytics, Twitter (X), and Meta Ads."
        },
      ],
      imageSrc: aiCardSEO,
    },
    {
      title: "AI-Driven Marketing & Ads",
      description: "<b>Maximize ROI</b> with targeted ad campaigns, <b>capture attention</b> using engaging ad copy, and <b>boost engagement</b> with creative ad formats and performance metrics analysis.",
      points: [
        {
          title: "Targeted Ad Campaigns",
          description: "Access highly targeted ad campaigns with precise audience segmentation, designed to maximize ROI.",
        },
        {
          title: "Engaging Ad Copy",
          description: "Use ready-to-deploy ad copy crafted with frameworks like AIDA and PAS to capture attention and drive action.",
        },
        {
          title: "Creative Ad Formats",
          description: "Benefit from AI-designed, visually appealing ad creatives that resonate with your audience and boost engagement.",
        },
        {
          title: "Performance Metrics",
          description: "Track and analyze key metrics like conversion rate, cost per click, and return on ad spend with AI-generated insights to optimize campaigns.",
        },
      ],
      imageSrc: aiCardMarketing,
    },
  ];

  return (
    <Flex
      w="full"
      bg="blue.500"
      _dark={{
        bg: "blue.500",
      }}
      justifyContent="center"
      p={{
        base: 0,
        lg: 4,
      }}
    >
      <Box maxW="100%"
        mx="auto" mb={8} mt={{base: 3, lg: 0}}>
        <Flex
          w="full"
          maxW="1700px"
          bg="blue.500"
          _dark={{
            bg: "blue.500",
          }}
          p={{base: 4, lg: 6}}
          justifyContent="space-between"
          alignItems="center"
          direction="column"
          textAlign={{
            md: "center",
          }}
        >
          <Box mb={4}>
            <Heading as="h2"
              _light={{
                color: "white",
              }}
              fontWeight="semibold"
              textTransform="uppercase"
              letterSpacing="wide"
              fontSize={{
                base: "3xl",
                lg: "4xl",
              }}
            >
              <Icon as={HiMiniSparkles} mr={3} />
              Supercharged dropshipping powered by AI
            </Heading>
            <Text
              mt={2}
              fontSize={{
                base: "lg",
                lg: "xl",
              }}
              mx={{
                lg: "auto",
              }}
              color="white"
              _dark={{
                color: "white",
              }}
            >
              Use AI-powered insights to drive decisions and stay ahead of your competitors
            </Text>
          </Box>
        </Flex>

        <Flex justifyContent="center" mb={4}>

          <Button
            colorScheme={currentSlide === 0 ? "none" : "gray"}
            onClick={() => goToSlide(0)}
            disabled={isSliderTransitioning}
            mr={2}
            fontSize="md"
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            p={5}
            border='0px'
            borderColor='blue.500'
            fontWeight="bold"
            rounded="2xl"
            color={currentSlide === 0 ? "white" : "blue.500"}
            background={currentSlide === 0 ? "navy" : "white"}
          >
            AI Analysis
          </Button>

          <Button
            colorScheme={currentSlide === 1 ? "none" : "gray"}
            onClick={() => goToSlide(1)}
            disabled={isSliderTransitioning}
            mr={2}
            fontSize="md"
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            p={5}
            border='0px'
            borderColor='blue.500'
            fontWeight="bold"
            rounded="2xl"
            color={currentSlide === 1 ? "white" : "blue.500"}
            background={currentSlide === 1 ? "navy" : "white"}
          >
            AI SEO
          </Button>

          <Button
            colorScheme={currentSlide === 2 ? "none" : "gray"}
            onClick={() => goToSlide(2)}
            disabled={isSliderTransitioning}
            fontSize="md"
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            p={5}
            border='0px'
            borderColor='blue.500'
            fontWeight="bold"
            rounded="2xl"
            color={currentSlide === 2 ? "white" : "blue.500"}
            background={currentSlide === 2 ? "navy" : "white"}
          >
            AI Marketing
          </Button>
        </Flex>

        <Box maxW="1500px"
        >
          <Slider {...settings} ref={sliderRef}>
            {cardsData.map((card, index) => (
              <Box key={index} mr={4}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <AICard {...card} />
                </div>
              </Box>
            ))}
          </Slider>
        </Box>




      </Box>
    </Flex>
  );
};

export default AIShowcaseSection;
