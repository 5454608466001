import React from 'react';
import { Box, Heading, Text, Flex, Stack, Divider, Icon, Button } from '@chakra-ui/react';
import DefaultAuth from './../layout/Default'
import { FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function PasswordResetSuccess() {

    const illustration = `${process.env.PUBLIC_URL}/assets/img/auth/droplla-side-banner.png`;

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: "100%", "md": "max-content" }}
                w='100%'
                mx={{ base: "auto", lg: "0px" }}
                me='auto'
                h='100%'
                alignItems='start'
                justifyContent='center'
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}

                flexDirection='column'>


                <Box

                    borderWidth="1px"
                    borderColor="gray.200"
                    borderRadius="xl"
                    p={4}
                    ml={{ base: 0, lg: 0 }}
                    mr={{ base: 0, lg: 0 }}
                    mt={4}
                >

                    <Flex alignItems="flex-start" w={{ base: "100%", md: "420px" }}>
                        <Stack direction="column" spacing={3} >
                            <Flex alignItems="center">
                            <Icon as={FaCheck} mr={3} color="green.500" boxSize={{ base: 6, md: 8 }}  />

                            <Heading color="secondaryGray.900" as="h2" size={{base: "md", md: "lg"}}>
                                Password Updated
                            </Heading>
                            </Flex>
                            <Divider mt={2} mb={4} />
                            <Text
                                color="secondaryGray.900"
                                fontSize="md"
                                marginBottom={2}
                                pl={{ base: 0, lg: 0 }}
                                whiteSpace="normal"
                                wordBreak="break-word"
                            > Your password update has been successful.</Text>
                            <Text
                                color="secondaryGray.900"
                                fontSize="md"

                                fontWeight="bold"
                                marginBottom={2}
                                pl={{ base: 0, lg: 0 }}
                                whiteSpace="normal"
                                wordBreak="break-word"
                            > You are back in business! </Text>
                            <Text
                                color="secondaryGray.900"
                                fontSize="md"
                                marginBottom={4}
                                pl={{ base: 0, lg: 0 }}
                                whiteSpace="normal"
                                wordBreak="break-word"
                            >
                                <Link to='/sign-in'>
                                    <Text
                                        color="droplla.500"
                                        as='span'
                                        ms='5px'
                                        mr='5px'
                                        fontWeight='800'>
                                        Sign-In
                                    </Text>
                                </Link>
                                to get started.</Text>
                            <Link to='/sign-in'>
                                <Button
                                    w='100%'
                                    h='50'
                                    variant="droplla"
                                    type="submit"
                                    mb={2}
                                >
                                    Sign In
                                </Button> </Link>
                       
                         
                        </Stack>
                    </Flex>
                </Box>
            </Flex>
        </DefaultAuth>
    );
}

export default PasswordResetSuccess;
