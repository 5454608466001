import React, { useEffect, useState } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useNavigate, useLocation} from "react-router-dom";

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { useForm } from 'react-hook-form';

import DefaultAuth from './../layout/Default'

import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

import Logger from '../../../components/utils/logger'
import { logRegisterAttempt } from "components/utils/analytics";
import { Helmet } from 'react-helmet-async';

const logger = new Logger('SignUp');

const illustration = `${process.env.PUBLIC_URL}/assets/img/auth/droplla-side-banner.png`;
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;


function SignUp() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "subtleGray.500";
  const textColorBrand = useColorModeValue("droplla.500", "white");

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [submissionError, setSubmissionError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);



  useEffect(() => {
    if (RECAPTCHA_SITE_KEY) {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_SITE_KEY}`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const recaptchaRef = React.useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const promoCode = params.get('promoCode') || '';

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setSubmissionError(null);

    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();

    const payload = {
      emailAddress: data.email,
      password: data.password,
      firstName: data.firstName || null,
      lastName: data.lastName || null,
      address: data.address || null,
      recaptchaToken: token,
      promoCode: data.promoCode || null,
    };

    try {
      const response = await fetch('/api/auth/email-profile/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.status === 204) {
        logRegisterAttempt("success", response.status)
      } else {
        logRegisterAttempt("failure", response.status)
      }

      if (response.status === 204) {
        navigate('/sign-up-success', { state: { message: `${data.email}` } });
      } else if (response.status === 400) {
        const errorData = await response.json();
        setSubmissionError(errorData.errorMessage || 'Sign-up form validation error');
      } else if (response.status === 403) {
        const errorData = await response.json();
        setSubmissionError(errorData.errorMessage || 'Sign-up form validation error');
      } else if (response.status === 409) {
        const errorData = await response.json();
        setSubmissionError(errorData.errorMessage || 'Email address already registered, please login');
      } else {
        setSubmissionError('Internal Error - Please try again in a few minutes');
      }
    } catch (error) {
      logRegisterAttempt("failure", "unknown")
      logger.error(error)
      setSubmissionError('Internal Error - Please try again in a few minutes');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
    <Helmet>
      <title>Sign Up - Create Your Account on droplla</title>
      <meta name="description" content="Join droplla today and unlock powerful AI-driven dropshipping tools. Sign up for free!" />
      <link rel="canonical" href="https://www.droplla.com/sign-up" />
    </Helmet>
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", "md": "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}

        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Try Droplla.com For Free
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='lg'>
            Get started - it's really quick!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>

          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={errors.firstName} isRequired>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='md'
                fontWeight='500'
                color='textColor'
                htmlFor='firstName'
              >
                First Name
              </FormLabel>
              <Input
                variant='droplla'
                ms={{ base: "0px", md: "0px" }}
                type='text'
                placeholder='Required'
                mb={1}
                size='lg'
                id='firstName'
                autoComplete="given-name"
                maxLength={50}
                {...register('firstName', {
                  required: 'First Name is required',
                  validate: {
                    noWhitespace: value => !value || value.trim() !== '' || 'First name cannot be whitespace only',
                    minLength: value => !value || value.replace(/\s+/g, '').length >= 2 || 'First name must be at least 2 characters long excluding whitespace',
                    noSymbolsNumbers: value => /^[A-Za-z-]*$/.test(value) || 'First name can only contain letters and hyphens',
                  },
                  maxLength: {
                    value: 50,
                    message: 'First name cannot exceed 50 characters',
                  },
                })}
              />
              <Box mb={4}>
                <FormErrorMessage fontSize="xs">{errors.firstName && errors.firstName.message}</FormErrorMessage>
              </Box>
            </FormControl>

            <FormControl isInvalid={errors.lastName} >
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='md'
                fontWeight='500'
                color='textColor'
                htmlFor='lastName'
              >
                Last Name
              </FormLabel>
              <Input
                variant='droplla'
                isRequired={false}
                ms={{ base: "0px", md: "0px" }}
                type='text'
                placeholder='Optional'
                mb={1}
                size='lg'
                id='lastName'
                autoComplete="family-name"
                maxLength={50}
                {...register('lastName', {
                  validate: {
                    noWhitespace: value => !value || value.trim() !== '' || 'Last name cannot be whitespace only',
                    minLength: value => !value || value.replace(/\s+/g, '').length >= 2 || 'Last name must be at least 2 characters long excluding whitespace',
                    noSymbolsNumbers: value => /^[A-Za-z-]*$/.test(value) || 'Last name can only contain letters and hyphens',
                  },
                  maxLength: {
                    value: 50,
                    message: 'Last name cannot exceed 50 characters',
                  },
                })}
              />
              <Box mb={4}>
                <FormErrorMessage fontSize="xs">{errors.lastName && errors.lastName.message}</FormErrorMessage>
              </Box>
            </FormControl>

            <Input
              variant='droplla'
              isRequired={false}
              ms={{ base: "0px", md: "0px" }}
              type='text'
              placeholder='Required'
              mb={1}
              size='lg'
              id='address'
              maxLength={70}
              style={{ display: 'none' }}
              {...register('address', {
                maxLength: {
                  value: 70,
                  message: 'Address cannot exceed 70 characters',
                },
              })}
            />

            <FormControl isInvalid={errors.email} isRequired>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='md'
                fontWeight='500'
                color='textColor'
                htmlFor='email'
              >
                Email
              </FormLabel>
              <Input
                variant='droplla'
                ms={{ base: "0px", md: "0px" }}
                type='email'
                placeholder='Email@provider.com'
                mb={1}
                size='lg'
                id='email'
                {...register('email', {
                  required: 'Email is required',
                  pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: 'Invalid email address' }
                })}
                autoComplete="email"
              />
              <Box mb={4}>
                <FormErrorMessage fontSize="xs">{errors.email && errors.email.message}</FormErrorMessage>
              </Box>
            </FormControl>

            <FormControl isInvalid={errors.password} isRequired>
              <FormLabel
                ms='4px'
                fontSize='md'
                fontWeight='500'
                color='textColor'
                display='flex'
                htmlFor='password'
              >
                Password
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  placeholder='Min. 8 characters'
                  mb={1}
                  size='lg'
                  type={show ? "text" : "password"}
                  variant='droplla'
                  id='password'
                  {...register('password', {
                    required: 'Password is required',
                    validate: {
                      minLengthExcludingWhitespace: value => value.replace(/\s/g, '').length >= 8 || 'Password must be at least 8 characters excluding whitespace'
                    }
                  })}
                  autoComplete="new-password"
                />
                <InputRightElement display='flex' alignItems='center' mt='5px'>
                  <Icon
                    color='textColorSecondary'
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              <Box mb={4}>
                <FormErrorMessage fontSize="xs">{errors.password && errors.password.message}</FormErrorMessage>
              </Box>
            </FormControl>

            <FormControl isInvalid={errors.confirmPassword} isRequired>
              <FormLabel
                ms='4px'
                fontSize='md'
                fontWeight='500'
                color='textColor'
                display='flex'
                htmlFor='confirmPassword'
              >
                Confirm Password
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  placeholder='Min. 8 characters'
                  mb={1}
                  size='lg'
                  type={show ? "text" : "password"}
                  variant='droplla'
                  id='confirmPassword'
                  {...register('confirmPassword', {
                    validate: value => value === watch('password') || 'Passwords must match'
                  })}
                  autoComplete="new-password"
                />
                <InputRightElement display='flex' alignItems='center' mt='5px'>
                  <Icon
                    color='textColorSecondary'
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              <Box mb={4}>
                <FormErrorMessage fontSize="xs">{errors.confirmPassword && errors.confirmPassword.message}</FormErrorMessage>
              </Box>
            </FormControl>

            <FormControl isInvalid={errors.promoCode} >
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='md'
                fontWeight='500'
                color='textColor'
                htmlFor='promoCode'
              >
                Promo Code
              </FormLabel>
              <Input
                variant='droplla'
                ms={{ base: "0px", md: "0px" }}
                type='text'
                placeholder='Optional'
                mb={1}
                size='lg'
                id='promoCode'
                defaultValue={promoCode}
                maxLength={50}
                {...register('promoCode', {
                  validate: {
                    noWhitespace: value => !value || value.trim() !== '' || 'Promo code cannot be whitespace only',
                    minLength: value => !value || value.replace(/\s+/g, '').length >= 2 || 'Promo code must be at least 2 characters long excluding whitespace',
                    noSymbolsNumbers: value => /^[0-9A-Za-z-]*$/.test(value) || 'Promo can only contain letters, numbers and hyphens',
                  },
                  maxLength: {
                    value: 50,
                    message: 'Promo code cannot exceed 50 characters',
                  },
                })}
              />
              <Box mb={4}>
                <FormErrorMessage fontSize="xs">{errors.promoCode && errors.promoCode.message}</FormErrorMessage>
              </Box>
            </FormControl>

            {submissionError && (
              <Text color="red.500" mb={4} fontSize="xs">
                {submissionError}
              </Text>
            )}

            <ReCAPTCHA
              sitekey={RECAPTCHA_SITE_KEY}
              size="invisible"
              ref={recaptchaRef}
            />

            <Button
              w='100%'
              h='50'
              mb='24px'
              variant="droplla"
              type="submit"
              mt={2}
              isLoading={isSubmitting}
            >
              Sign Up
            </Button>
          </form>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text
              mb={4}
              ms='4px'
              color={textColorSecondary}
              fontWeight='400'
              fontSize='md'>
              Already registered?
              <Link to='/sign-in'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Sign-In
                </Text>
              </Link>
            </Text>
            <Text color={textColorSecondary} fontWeight='400' fontSize='xs' >

              By signing up, you agree to our


              <Link to='/legal/privacy-policy' target="_blank" rel="noopener noreferrer">
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>Privacy Policy</Text>
              </Link>,
              <Link to='/legal/cookies-policy' target="_blank" rel="noopener noreferrer">
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  me='5px'
                  fontWeight='500'>Cookies Policy</Text>
              </Link>
              and
              <Link to='/legal/terms-of-use' target="_blank" rel="noopener noreferrer">
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  me='5px'
                  fontWeight='500'>Terms & Conditions.</Text>
              </Link>
              Droplla is protected by reCAPTCHA, and the Google
              <Link to='https://policies.google.com/privacy' target="_blank" rel="noopener noreferrer">
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  me='5px'
                  fontWeight='500'>Privacy Policy</Text>
              </Link>
              and
              <Link to='https://policies.google.com/terms' target="_blank" rel="noopener noreferrer">
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  me='5px'
                  fontWeight='500'>Terms of Service</Text>
              </Link>
              also apply.
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
    </>
  );
}

export default SignUp;
